/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { Binder } from '../model';
// @ts-ignore
import { BinderItemOfDocument } from '../model';
// @ts-ignore
import { BinderWithItems } from '../model';
// @ts-ignore
import { BookmarkWithNaturalId } from '../model';
// @ts-ignore
import { CountsByKey } from '../model';
// @ts-ignore
import { DeleteBinderFolderParamsBody } from '../model';
// @ts-ignore
import { DeleteBinderParamsBody } from '../model';
// @ts-ignore
import { DeleteBookmarkParamsBody } from '../model';
// @ts-ignore
import { DeleteHighlightParamsBody } from '../model';
// @ts-ignore
import { Folder } from '../model';
// @ts-ignore
import { GetSubFoldersAndBindersResponse } from '../model';
// @ts-ignore
import { HighlightWithNaturalId } from '../model';
// @ts-ignore
import { ModifyBinderFolderParamsBody } from '../model';
// @ts-ignore
import { ModifyBinderParamsBody } from '../model';
// @ts-ignore
import { ModifyBookmarkParamsBody } from '../model';
// @ts-ignore
import { ModifyHighlightParamsBody } from '../model';
// @ts-ignore
import { NewBinderFolderParamsBody } from '../model';
// @ts-ignore
import { NewBinderParamsBody } from '../model';
// @ts-ignore
import { NewHighlightParamsBody } from '../model';
// @ts-ignore
import { NewPdfBookmarkParamsBody } from '../model';
// @ts-ignore
import { NewWebBookmarkParamsBody } from '../model';
// @ts-ignore
import { TotalCount } from '../model';
/**
 * BindersApi - axios parameter creator
 * @export
 */
export const BindersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary バインダーを削除する
         * @param {string} id バインダー ID
         * @param {DeleteBinderParamsBody} [deleteBinderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBinder: async (id: string, deleteBinderParamsBody?: DeleteBinderParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBinder', 'id', id)
            const localVarPath = `/binders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBinderParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary フォルダーを論理削除する
         * @param {string} id フォルダー ID
         * @param {DeleteBinderFolderParamsBody} [deleteBinderFolderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBinderFolder: async (id: string, deleteBinderFolderParamsBody?: DeleteBinderFolderParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('deleteBinderFolder', 'id', id)
            const localVarPath = `/binder-folders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBinderFolderParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ブックマークを削除する
         * @param {string} bookmarkId 削除するブックマークの ID
         * @param {DeleteBookmarkParamsBody} [deleteBookmarkParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBookmark: async (bookmarkId: string, deleteBookmarkParamsBody?: DeleteBookmarkParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookmarkId' is not null or undefined
            assertParamExists('deleteBookmark', 'bookmarkId', bookmarkId)
            const localVarPath = `/bookmarks/{bookmarkId}`
                .replace(`{${"bookmarkId"}}`, encodeURIComponent(String(bookmarkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteBookmarkParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ハイライトを削除する
         * @param {string} highlightId 削除するハイライトの ID
         * @param {DeleteHighlightParamsBody} [deleteHighlightParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHighlight: async (highlightId: string, deleteHighlightParamsBody?: DeleteHighlightParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'highlightId' is not null or undefined
            assertParamExists('deleteHighlight', 'highlightId', highlightId)
            const localVarPath = `/highlights/{highlightId}`
                .replace(`{${"highlightId"}}`, encodeURIComponent(String(highlightId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(deleteHighlightParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ID で指定された文献に設定されているブックマークやハイライトを取得する
         * @param {string} naturalId 文献の ID (書籍の ISBN など)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBinderItemsOfDocument: async (naturalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'naturalId' is not null or undefined
            assertParamExists('getBinderItemsOfDocument', 'naturalId', naturalId)
            const localVarPath = `/documents/{naturalId}/binder-items`
                .replace(`{${"naturalId"}}`, encodeURIComponent(String(naturalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary バインダーそのものの情報とともにブックマーク・ハイライトを取得する
         * @param {string} id バインダー ID
         * @param {boolean} [shared] 他者から共有されたバインダーを取得するためにこの API を呼び出す場合、true を設定する
         * @param {boolean} [withOtherBinderHighlights] このバインダーに収録されているブックマーク・ハイライトの文献について、他のバインダーに含まれるハイライトも一緒に取得したい場合は true を設定する。 ただし &#x60;shared&#x60; に true が設定されている場合、このパラメータは無視される (false が指定された場合の挙動になる)。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBinderWithItems: async (id: string, shared?: boolean, withOtherBinderHighlights?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getBinderWithItems', 'id', id)
            const localVarPath = `/binders/{id}/items`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (shared !== undefined) {
                localVarQueryParameter['shared'] = shared;
            }

            if (withOtherBinderHighlights !== undefined) {
                localVarQueryParameter['withOtherBinderHighlights'] = withOtherBinderHighlights;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary バインダーの全数カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBindersCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/binders/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary バインダー別のアイテムカウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemsCountPerBinder: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/binders/items/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary あるフォルダーに格納されている子フォルダーおよびバインダーの一覧を得る。
         * @param {string} id フォルダー ID。ルートフォルダーを指定する場合は 0 を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubFoldersAndBinders: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSubFoldersAndBinders', 'id', id)
            const localVarPath = `/binder-folders/{id}/children`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * バインダーは最近ブックマーク or ハイライトを追加した順に列挙される
         * @summary フォルダー階層やバインダーの種類を問わず、ユーザが所有しているすべてのバインダーの一覧を得る
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllBinders: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/binders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary バインダーの名前などを変更する
         * @param {string} id バインダー ID
         * @param {ModifyBinderParamsBody} [modifyBinderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyBinder: async (id: string, modifyBinderParamsBody?: ModifyBinderParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modifyBinder', 'id', id)
            const localVarPath = `/binders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyBinderParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary フォルダーの名前や親フォルダーなどを変更する
         * @param {string} id フォルダー ID
         * @param {ModifyBinderFolderParamsBody} [modifyBinderFolderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyBinderFolder: async (id: string, modifyBinderFolderParamsBody?: ModifyBinderFolderParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('modifyBinderFolder', 'id', id)
            const localVarPath = `/binder-folders/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyBinderFolderParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 別のバインダーにブックマークを移動する際もこの API を利用します
         * @summary ブックマークのメモなどを変更する
         * @param {string} bookmarkId 変更するブックマークの ID
         * @param {ModifyBookmarkParamsBody} [modifyBookmarkParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyBookmark: async (bookmarkId: string, modifyBookmarkParamsBody?: ModifyBookmarkParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'bookmarkId' is not null or undefined
            assertParamExists('modifyBookmark', 'bookmarkId', bookmarkId)
            const localVarPath = `/bookmarks/{bookmarkId}`
                .replace(`{${"bookmarkId"}}`, encodeURIComponent(String(bookmarkId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyBookmarkParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 別のバインダーにハイライトを移動する際もこの API を利用します
         * @summary ハイライトのメモなどを変更する
         * @param {string} highlightId 変更するハイライトの ID
         * @param {ModifyHighlightParamsBody} [modifyHighlightParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyHighlight: async (highlightId: string, modifyHighlightParamsBody?: ModifyHighlightParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'highlightId' is not null or undefined
            assertParamExists('modifyHighlight', 'highlightId', highlightId)
            const localVarPath = `/highlights/{highlightId}`
                .replace(`{${"highlightId"}}`, encodeURIComponent(String(highlightId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(modifyHighlightParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary フォルダーの配下に新たにバインダーを作成する
         * @param {string} id フォルダー ID。ルートフォルダーを指定する場合は 0 を指定する
         * @param {NewBinderParamsBody} [newBinderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newBinder: async (id: string, newBinderParamsBody?: NewBinderParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('newBinder', 'id', id)
            const localVarPath = `/binder-folders/{id}/binder`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newBinderParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary フォルダーを新規作成する
         * @param {NewBinderFolderParamsBody} [newBinderFolderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newBinderFolder: async (newBinderFolderParamsBody?: NewBinderFolderParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/binder-folders`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newBinderFolderParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary バインダーに新たなハイライトを登録する
         * @param {string} binderId ハイライトを登録する先のバインダー ID
         * @param {NewHighlightParamsBody} [newHighlightParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newHighlight: async (binderId: string, newHighlightParamsBody?: NewHighlightParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'binderId' is not null or undefined
            assertParamExists('newHighlight', 'binderId', binderId)
            const localVarPath = `/binders/{binderId}/highlight`
                .replace(`{${"binderId"}}`, encodeURIComponent(String(binderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newHighlightParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary バインダーに新たな PDF ビューのブックマークを登録する
         * @param {string} binderId ブックマークを登録する先のバインダー ID
         * @param {NewPdfBookmarkParamsBody} [newPdfBookmarkParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newPdfBookmark: async (binderId: string, newPdfBookmarkParamsBody?: NewPdfBookmarkParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'binderId' is not null or undefined
            assertParamExists('newPdfBookmark', 'binderId', binderId)
            const localVarPath = `/binders/{binderId}/bookmark/pdf`
                .replace(`{${"binderId"}}`, encodeURIComponent(String(binderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newPdfBookmarkParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary バインダーに新たなブックマークを登録する
         * @param {string} binderId ブックマークを登録する先のバインダー ID
         * @param {NewWebBookmarkParamsBody} [newWebBookmarkParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newWebBookmark: async (binderId: string, newWebBookmarkParamsBody?: NewWebBookmarkParamsBody, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'binderId' is not null or undefined
            assertParamExists('newWebBookmark', 'binderId', binderId)
            const localVarPath = `/binders/{binderId}/bookmark`
                .replace(`{${"binderId"}}`, encodeURIComponent(String(binderId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(newWebBookmarkParamsBody, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * BindersApi - functional programming interface
 * @export
 */
export const BindersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = BindersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary バインダーを削除する
         * @param {string} id バインダー ID
         * @param {DeleteBinderParamsBody} [deleteBinderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBinder(id: string, deleteBinderParamsBody?: DeleteBinderParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBinder(id, deleteBinderParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary フォルダーを論理削除する
         * @param {string} id フォルダー ID
         * @param {DeleteBinderFolderParamsBody} [deleteBinderFolderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBinderFolder(id: string, deleteBinderFolderParamsBody?: DeleteBinderFolderParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBinderFolder(id, deleteBinderFolderParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ブックマークを削除する
         * @param {string} bookmarkId 削除するブックマークの ID
         * @param {DeleteBookmarkParamsBody} [deleteBookmarkParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteBookmark(bookmarkId: string, deleteBookmarkParamsBody?: DeleteBookmarkParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteBookmark(bookmarkId, deleteBookmarkParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ハイライトを削除する
         * @param {string} highlightId 削除するハイライトの ID
         * @param {DeleteHighlightParamsBody} [deleteHighlightParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteHighlight(highlightId: string, deleteHighlightParamsBody?: DeleteHighlightParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteHighlight(highlightId, deleteHighlightParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ID で指定された文献に設定されているブックマークやハイライトを取得する
         * @param {string} naturalId 文献の ID (書籍の ISBN など)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBinderItemsOfDocument(naturalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BinderItemOfDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBinderItemsOfDocument(naturalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary バインダーそのものの情報とともにブックマーク・ハイライトを取得する
         * @param {string} id バインダー ID
         * @param {boolean} [shared] 他者から共有されたバインダーを取得するためにこの API を呼び出す場合、true を設定する
         * @param {boolean} [withOtherBinderHighlights] このバインダーに収録されているブックマーク・ハイライトの文献について、他のバインダーに含まれるハイライトも一緒に取得したい場合は true を設定する。 ただし &#x60;shared&#x60; に true が設定されている場合、このパラメータは無視される (false が指定された場合の挙動になる)。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBinderWithItems(id: string, shared?: boolean, withOtherBinderHighlights?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BinderWithItems>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBinderWithItems(id, shared, withOtherBinderHighlights, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary バインダーの全数カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBindersCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBindersCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary バインダー別のアイテムカウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getItemsCountPerBinder(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountsByKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getItemsCountPerBinder(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary あるフォルダーに格納されている子フォルダーおよびバインダーの一覧を得る。
         * @param {string} id フォルダー ID。ルートフォルダーを指定する場合は 0 を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSubFoldersAndBinders(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetSubFoldersAndBindersResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSubFoldersAndBinders(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * バインダーは最近ブックマーク or ハイライトを追加した順に列挙される
         * @summary フォルダー階層やバインダーの種類を問わず、ユーザが所有しているすべてのバインダーの一覧を得る
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async listAllBinders(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Binder>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.listAllBinders(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary バインダーの名前などを変更する
         * @param {string} id バインダー ID
         * @param {ModifyBinderParamsBody} [modifyBinderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyBinder(id: string, modifyBinderParamsBody?: ModifyBinderParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Binder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyBinder(id, modifyBinderParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary フォルダーの名前や親フォルダーなどを変更する
         * @param {string} id フォルダー ID
         * @param {ModifyBinderFolderParamsBody} [modifyBinderFolderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyBinderFolder(id: string, modifyBinderFolderParamsBody?: ModifyBinderFolderParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Folder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyBinderFolder(id, modifyBinderFolderParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 別のバインダーにブックマークを移動する際もこの API を利用します
         * @summary ブックマークのメモなどを変更する
         * @param {string} bookmarkId 変更するブックマークの ID
         * @param {ModifyBookmarkParamsBody} [modifyBookmarkParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyBookmark(bookmarkId: string, modifyBookmarkParamsBody?: ModifyBookmarkParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookmarkWithNaturalId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyBookmark(bookmarkId, modifyBookmarkParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 別のバインダーにハイライトを移動する際もこの API を利用します
         * @summary ハイライトのメモなどを変更する
         * @param {string} highlightId 変更するハイライトの ID
         * @param {ModifyHighlightParamsBody} [modifyHighlightParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async modifyHighlight(highlightId: string, modifyHighlightParamsBody?: ModifyHighlightParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HighlightWithNaturalId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.modifyHighlight(highlightId, modifyHighlightParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary フォルダーの配下に新たにバインダーを作成する
         * @param {string} id フォルダー ID。ルートフォルダーを指定する場合は 0 を指定する
         * @param {NewBinderParamsBody} [newBinderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newBinder(id: string, newBinderParamsBody?: NewBinderParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Binder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newBinder(id, newBinderParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary フォルダーを新規作成する
         * @param {NewBinderFolderParamsBody} [newBinderFolderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newBinderFolder(newBinderFolderParamsBody?: NewBinderFolderParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Folder>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newBinderFolder(newBinderFolderParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary バインダーに新たなハイライトを登録する
         * @param {string} binderId ハイライトを登録する先のバインダー ID
         * @param {NewHighlightParamsBody} [newHighlightParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newHighlight(binderId: string, newHighlightParamsBody?: NewHighlightParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<HighlightWithNaturalId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newHighlight(binderId, newHighlightParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary バインダーに新たな PDF ビューのブックマークを登録する
         * @param {string} binderId ブックマークを登録する先のバインダー ID
         * @param {NewPdfBookmarkParamsBody} [newPdfBookmarkParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newPdfBookmark(binderId: string, newPdfBookmarkParamsBody?: NewPdfBookmarkParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookmarkWithNaturalId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newPdfBookmark(binderId, newPdfBookmarkParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary バインダーに新たなブックマークを登録する
         * @param {string} binderId ブックマークを登録する先のバインダー ID
         * @param {NewWebBookmarkParamsBody} [newWebBookmarkParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async newWebBookmark(binderId: string, newWebBookmarkParamsBody?: NewWebBookmarkParamsBody, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BookmarkWithNaturalId>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.newWebBookmark(binderId, newWebBookmarkParamsBody, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * BindersApi - factory interface
 * @export
 */
export const BindersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = BindersApiFp(configuration)
    return {
        /**
         * 
         * @summary バインダーを削除する
         * @param {string} id バインダー ID
         * @param {DeleteBinderParamsBody} [deleteBinderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBinder(id: string, deleteBinderParamsBody?: DeleteBinderParamsBody, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBinder(id, deleteBinderParamsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary フォルダーを論理削除する
         * @param {string} id フォルダー ID
         * @param {DeleteBinderFolderParamsBody} [deleteBinderFolderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBinderFolder(id: string, deleteBinderFolderParamsBody?: DeleteBinderFolderParamsBody, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBinderFolder(id, deleteBinderFolderParamsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ブックマークを削除する
         * @param {string} bookmarkId 削除するブックマークの ID
         * @param {DeleteBookmarkParamsBody} [deleteBookmarkParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteBookmark(bookmarkId: string, deleteBookmarkParamsBody?: DeleteBookmarkParamsBody, options?: any): AxiosPromise<void> {
            return localVarFp.deleteBookmark(bookmarkId, deleteBookmarkParamsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ハイライトを削除する
         * @param {string} highlightId 削除するハイライトの ID
         * @param {DeleteHighlightParamsBody} [deleteHighlightParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteHighlight(highlightId: string, deleteHighlightParamsBody?: DeleteHighlightParamsBody, options?: any): AxiosPromise<void> {
            return localVarFp.deleteHighlight(highlightId, deleteHighlightParamsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ID で指定された文献に設定されているブックマークやハイライトを取得する
         * @param {string} naturalId 文献の ID (書籍の ISBN など)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBinderItemsOfDocument(naturalId: string, options?: any): AxiosPromise<Array<BinderItemOfDocument>> {
            return localVarFp.getBinderItemsOfDocument(naturalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary バインダーそのものの情報とともにブックマーク・ハイライトを取得する
         * @param {string} id バインダー ID
         * @param {boolean} [shared] 他者から共有されたバインダーを取得するためにこの API を呼び出す場合、true を設定する
         * @param {boolean} [withOtherBinderHighlights] このバインダーに収録されているブックマーク・ハイライトの文献について、他のバインダーに含まれるハイライトも一緒に取得したい場合は true を設定する。 ただし &#x60;shared&#x60; に true が設定されている場合、このパラメータは無視される (false が指定された場合の挙動になる)。 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBinderWithItems(id: string, shared?: boolean, withOtherBinderHighlights?: boolean, options?: any): AxiosPromise<BinderWithItems> {
            return localVarFp.getBinderWithItems(id, shared, withOtherBinderHighlights, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary バインダーの全数カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBindersCount(options?: any): AxiosPromise<TotalCount> {
            return localVarFp.getBindersCount(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary バインダー別のアイテムカウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getItemsCountPerBinder(options?: any): AxiosPromise<CountsByKey> {
            return localVarFp.getItemsCountPerBinder(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary あるフォルダーに格納されている子フォルダーおよびバインダーの一覧を得る。
         * @param {string} id フォルダー ID。ルートフォルダーを指定する場合は 0 を指定する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSubFoldersAndBinders(id: string, options?: any): AxiosPromise<GetSubFoldersAndBindersResponse> {
            return localVarFp.getSubFoldersAndBinders(id, options).then((request) => request(axios, basePath));
        },
        /**
         * バインダーは最近ブックマーク or ハイライトを追加した順に列挙される
         * @summary フォルダー階層やバインダーの種類を問わず、ユーザが所有しているすべてのバインダーの一覧を得る
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listAllBinders(options?: any): AxiosPromise<Array<Binder>> {
            return localVarFp.listAllBinders(options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary バインダーの名前などを変更する
         * @param {string} id バインダー ID
         * @param {ModifyBinderParamsBody} [modifyBinderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyBinder(id: string, modifyBinderParamsBody?: ModifyBinderParamsBody, options?: any): AxiosPromise<Binder> {
            return localVarFp.modifyBinder(id, modifyBinderParamsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary フォルダーの名前や親フォルダーなどを変更する
         * @param {string} id フォルダー ID
         * @param {ModifyBinderFolderParamsBody} [modifyBinderFolderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyBinderFolder(id: string, modifyBinderFolderParamsBody?: ModifyBinderFolderParamsBody, options?: any): AxiosPromise<Folder> {
            return localVarFp.modifyBinderFolder(id, modifyBinderFolderParamsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 別のバインダーにブックマークを移動する際もこの API を利用します
         * @summary ブックマークのメモなどを変更する
         * @param {string} bookmarkId 変更するブックマークの ID
         * @param {ModifyBookmarkParamsBody} [modifyBookmarkParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyBookmark(bookmarkId: string, modifyBookmarkParamsBody?: ModifyBookmarkParamsBody, options?: any): AxiosPromise<BookmarkWithNaturalId> {
            return localVarFp.modifyBookmark(bookmarkId, modifyBookmarkParamsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 別のバインダーにハイライトを移動する際もこの API を利用します
         * @summary ハイライトのメモなどを変更する
         * @param {string} highlightId 変更するハイライトの ID
         * @param {ModifyHighlightParamsBody} [modifyHighlightParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        modifyHighlight(highlightId: string, modifyHighlightParamsBody?: ModifyHighlightParamsBody, options?: any): AxiosPromise<HighlightWithNaturalId> {
            return localVarFp.modifyHighlight(highlightId, modifyHighlightParamsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary フォルダーの配下に新たにバインダーを作成する
         * @param {string} id フォルダー ID。ルートフォルダーを指定する場合は 0 を指定する
         * @param {NewBinderParamsBody} [newBinderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newBinder(id: string, newBinderParamsBody?: NewBinderParamsBody, options?: any): AxiosPromise<Binder> {
            return localVarFp.newBinder(id, newBinderParamsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary フォルダーを新規作成する
         * @param {NewBinderFolderParamsBody} [newBinderFolderParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newBinderFolder(newBinderFolderParamsBody?: NewBinderFolderParamsBody, options?: any): AxiosPromise<Folder> {
            return localVarFp.newBinderFolder(newBinderFolderParamsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary バインダーに新たなハイライトを登録する
         * @param {string} binderId ハイライトを登録する先のバインダー ID
         * @param {NewHighlightParamsBody} [newHighlightParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newHighlight(binderId: string, newHighlightParamsBody?: NewHighlightParamsBody, options?: any): AxiosPromise<HighlightWithNaturalId> {
            return localVarFp.newHighlight(binderId, newHighlightParamsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary バインダーに新たな PDF ビューのブックマークを登録する
         * @param {string} binderId ブックマークを登録する先のバインダー ID
         * @param {NewPdfBookmarkParamsBody} [newPdfBookmarkParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newPdfBookmark(binderId: string, newPdfBookmarkParamsBody?: NewPdfBookmarkParamsBody, options?: any): AxiosPromise<BookmarkWithNaturalId> {
            return localVarFp.newPdfBookmark(binderId, newPdfBookmarkParamsBody, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary バインダーに新たなブックマークを登録する
         * @param {string} binderId ブックマークを登録する先のバインダー ID
         * @param {NewWebBookmarkParamsBody} [newWebBookmarkParamsBody] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        newWebBookmark(binderId: string, newWebBookmarkParamsBody?: NewWebBookmarkParamsBody, options?: any): AxiosPromise<BookmarkWithNaturalId> {
            return localVarFp.newWebBookmark(binderId, newWebBookmarkParamsBody, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * BindersApi - interface
 * @export
 * @interface BindersApi
 */
export interface BindersApiInterface {
    /**
     * 
     * @summary バインダーを削除する
     * @param {string} id バインダー ID
     * @param {DeleteBinderParamsBody} [deleteBinderParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    deleteBinder(id: string, deleteBinderParamsBody?: DeleteBinderParamsBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary フォルダーを論理削除する
     * @param {string} id フォルダー ID
     * @param {DeleteBinderFolderParamsBody} [deleteBinderFolderParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    deleteBinderFolder(id: string, deleteBinderFolderParamsBody?: DeleteBinderFolderParamsBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary ブックマークを削除する
     * @param {string} bookmarkId 削除するブックマークの ID
     * @param {DeleteBookmarkParamsBody} [deleteBookmarkParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    deleteBookmark(bookmarkId: string, deleteBookmarkParamsBody?: DeleteBookmarkParamsBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary ハイライトを削除する
     * @param {string} highlightId 削除するハイライトの ID
     * @param {DeleteHighlightParamsBody} [deleteHighlightParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    deleteHighlight(highlightId: string, deleteHighlightParamsBody?: DeleteHighlightParamsBody, options?: AxiosRequestConfig): AxiosPromise<void>;

    /**
     * 
     * @summary ID で指定された文献に設定されているブックマークやハイライトを取得する
     * @param {string} naturalId 文献の ID (書籍の ISBN など)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    getBinderItemsOfDocument(naturalId: string, options?: AxiosRequestConfig): AxiosPromise<Array<BinderItemOfDocument>>;

    /**
     * 
     * @summary バインダーそのものの情報とともにブックマーク・ハイライトを取得する
     * @param {string} id バインダー ID
     * @param {boolean} [shared] 他者から共有されたバインダーを取得するためにこの API を呼び出す場合、true を設定する
     * @param {boolean} [withOtherBinderHighlights] このバインダーに収録されているブックマーク・ハイライトの文献について、他のバインダーに含まれるハイライトも一緒に取得したい場合は true を設定する。 ただし &#x60;shared&#x60; に true が設定されている場合、このパラメータは無視される (false が指定された場合の挙動になる)。 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    getBinderWithItems(id: string, shared?: boolean, withOtherBinderHighlights?: boolean, options?: AxiosRequestConfig): AxiosPromise<BinderWithItems>;

    /**
     * 
     * @summary バインダーの全数カウント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    getBindersCount(options?: AxiosRequestConfig): AxiosPromise<TotalCount>;

    /**
     * 
     * @summary バインダー別のアイテムカウント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    getItemsCountPerBinder(options?: AxiosRequestConfig): AxiosPromise<CountsByKey>;

    /**
     * 
     * @summary あるフォルダーに格納されている子フォルダーおよびバインダーの一覧を得る。
     * @param {string} id フォルダー ID。ルートフォルダーを指定する場合は 0 を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    getSubFoldersAndBinders(id: string, options?: AxiosRequestConfig): AxiosPromise<GetSubFoldersAndBindersResponse>;

    /**
     * バインダーは最近ブックマーク or ハイライトを追加した順に列挙される
     * @summary フォルダー階層やバインダーの種類を問わず、ユーザが所有しているすべてのバインダーの一覧を得る
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    listAllBinders(options?: AxiosRequestConfig): AxiosPromise<Array<Binder>>;

    /**
     * 
     * @summary バインダーの名前などを変更する
     * @param {string} id バインダー ID
     * @param {ModifyBinderParamsBody} [modifyBinderParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    modifyBinder(id: string, modifyBinderParamsBody?: ModifyBinderParamsBody, options?: AxiosRequestConfig): AxiosPromise<Binder>;

    /**
     * 
     * @summary フォルダーの名前や親フォルダーなどを変更する
     * @param {string} id フォルダー ID
     * @param {ModifyBinderFolderParamsBody} [modifyBinderFolderParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    modifyBinderFolder(id: string, modifyBinderFolderParamsBody?: ModifyBinderFolderParamsBody, options?: AxiosRequestConfig): AxiosPromise<Folder>;

    /**
     * 別のバインダーにブックマークを移動する際もこの API を利用します
     * @summary ブックマークのメモなどを変更する
     * @param {string} bookmarkId 変更するブックマークの ID
     * @param {ModifyBookmarkParamsBody} [modifyBookmarkParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    modifyBookmark(bookmarkId: string, modifyBookmarkParamsBody?: ModifyBookmarkParamsBody, options?: AxiosRequestConfig): AxiosPromise<BookmarkWithNaturalId>;

    /**
     * 別のバインダーにハイライトを移動する際もこの API を利用します
     * @summary ハイライトのメモなどを変更する
     * @param {string} highlightId 変更するハイライトの ID
     * @param {ModifyHighlightParamsBody} [modifyHighlightParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    modifyHighlight(highlightId: string, modifyHighlightParamsBody?: ModifyHighlightParamsBody, options?: AxiosRequestConfig): AxiosPromise<HighlightWithNaturalId>;

    /**
     * 
     * @summary フォルダーの配下に新たにバインダーを作成する
     * @param {string} id フォルダー ID。ルートフォルダーを指定する場合は 0 を指定する
     * @param {NewBinderParamsBody} [newBinderParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    newBinder(id: string, newBinderParamsBody?: NewBinderParamsBody, options?: AxiosRequestConfig): AxiosPromise<Binder>;

    /**
     * 
     * @summary フォルダーを新規作成する
     * @param {NewBinderFolderParamsBody} [newBinderFolderParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    newBinderFolder(newBinderFolderParamsBody?: NewBinderFolderParamsBody, options?: AxiosRequestConfig): AxiosPromise<Folder>;

    /**
     * 
     * @summary バインダーに新たなハイライトを登録する
     * @param {string} binderId ハイライトを登録する先のバインダー ID
     * @param {NewHighlightParamsBody} [newHighlightParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    newHighlight(binderId: string, newHighlightParamsBody?: NewHighlightParamsBody, options?: AxiosRequestConfig): AxiosPromise<HighlightWithNaturalId>;

    /**
     * 
     * @summary バインダーに新たな PDF ビューのブックマークを登録する
     * @param {string} binderId ブックマークを登録する先のバインダー ID
     * @param {NewPdfBookmarkParamsBody} [newPdfBookmarkParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    newPdfBookmark(binderId: string, newPdfBookmarkParamsBody?: NewPdfBookmarkParamsBody, options?: AxiosRequestConfig): AxiosPromise<BookmarkWithNaturalId>;

    /**
     * 
     * @summary バインダーに新たなブックマークを登録する
     * @param {string} binderId ブックマークを登録する先のバインダー ID
     * @param {NewWebBookmarkParamsBody} [newWebBookmarkParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApiInterface
     */
    newWebBookmark(binderId: string, newWebBookmarkParamsBody?: NewWebBookmarkParamsBody, options?: AxiosRequestConfig): AxiosPromise<BookmarkWithNaturalId>;

}

/**
 * BindersApi - object-oriented interface
 * @export
 * @class BindersApi
 * @extends {BaseAPI}
 */
export class BindersApi extends BaseAPI implements BindersApiInterface {
    /**
     * 
     * @summary バインダーを削除する
     * @param {string} id バインダー ID
     * @param {DeleteBinderParamsBody} [deleteBinderParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public deleteBinder(id: string, deleteBinderParamsBody?: DeleteBinderParamsBody, options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).deleteBinder(id, deleteBinderParamsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary フォルダーを論理削除する
     * @param {string} id フォルダー ID
     * @param {DeleteBinderFolderParamsBody} [deleteBinderFolderParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public deleteBinderFolder(id: string, deleteBinderFolderParamsBody?: DeleteBinderFolderParamsBody, options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).deleteBinderFolder(id, deleteBinderFolderParamsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ブックマークを削除する
     * @param {string} bookmarkId 削除するブックマークの ID
     * @param {DeleteBookmarkParamsBody} [deleteBookmarkParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public deleteBookmark(bookmarkId: string, deleteBookmarkParamsBody?: DeleteBookmarkParamsBody, options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).deleteBookmark(bookmarkId, deleteBookmarkParamsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ハイライトを削除する
     * @param {string} highlightId 削除するハイライトの ID
     * @param {DeleteHighlightParamsBody} [deleteHighlightParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public deleteHighlight(highlightId: string, deleteHighlightParamsBody?: DeleteHighlightParamsBody, options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).deleteHighlight(highlightId, deleteHighlightParamsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ID で指定された文献に設定されているブックマークやハイライトを取得する
     * @param {string} naturalId 文献の ID (書籍の ISBN など)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public getBinderItemsOfDocument(naturalId: string, options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).getBinderItemsOfDocument(naturalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary バインダーそのものの情報とともにブックマーク・ハイライトを取得する
     * @param {string} id バインダー ID
     * @param {boolean} [shared] 他者から共有されたバインダーを取得するためにこの API を呼び出す場合、true を設定する
     * @param {boolean} [withOtherBinderHighlights] このバインダーに収録されているブックマーク・ハイライトの文献について、他のバインダーに含まれるハイライトも一緒に取得したい場合は true を設定する。 ただし &#x60;shared&#x60; に true が設定されている場合、このパラメータは無視される (false が指定された場合の挙動になる)。 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public getBinderWithItems(id: string, shared?: boolean, withOtherBinderHighlights?: boolean, options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).getBinderWithItems(id, shared, withOtherBinderHighlights, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary バインダーの全数カウント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public getBindersCount(options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).getBindersCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary バインダー別のアイテムカウント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public getItemsCountPerBinder(options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).getItemsCountPerBinder(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary あるフォルダーに格納されている子フォルダーおよびバインダーの一覧を得る。
     * @param {string} id フォルダー ID。ルートフォルダーを指定する場合は 0 を指定する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public getSubFoldersAndBinders(id: string, options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).getSubFoldersAndBinders(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * バインダーは最近ブックマーク or ハイライトを追加した順に列挙される
     * @summary フォルダー階層やバインダーの種類を問わず、ユーザが所有しているすべてのバインダーの一覧を得る
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public listAllBinders(options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).listAllBinders(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary バインダーの名前などを変更する
     * @param {string} id バインダー ID
     * @param {ModifyBinderParamsBody} [modifyBinderParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public modifyBinder(id: string, modifyBinderParamsBody?: ModifyBinderParamsBody, options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).modifyBinder(id, modifyBinderParamsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary フォルダーの名前や親フォルダーなどを変更する
     * @param {string} id フォルダー ID
     * @param {ModifyBinderFolderParamsBody} [modifyBinderFolderParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public modifyBinderFolder(id: string, modifyBinderFolderParamsBody?: ModifyBinderFolderParamsBody, options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).modifyBinderFolder(id, modifyBinderFolderParamsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 別のバインダーにブックマークを移動する際もこの API を利用します
     * @summary ブックマークのメモなどを変更する
     * @param {string} bookmarkId 変更するブックマークの ID
     * @param {ModifyBookmarkParamsBody} [modifyBookmarkParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public modifyBookmark(bookmarkId: string, modifyBookmarkParamsBody?: ModifyBookmarkParamsBody, options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).modifyBookmark(bookmarkId, modifyBookmarkParamsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 別のバインダーにハイライトを移動する際もこの API を利用します
     * @summary ハイライトのメモなどを変更する
     * @param {string} highlightId 変更するハイライトの ID
     * @param {ModifyHighlightParamsBody} [modifyHighlightParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public modifyHighlight(highlightId: string, modifyHighlightParamsBody?: ModifyHighlightParamsBody, options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).modifyHighlight(highlightId, modifyHighlightParamsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary フォルダーの配下に新たにバインダーを作成する
     * @param {string} id フォルダー ID。ルートフォルダーを指定する場合は 0 を指定する
     * @param {NewBinderParamsBody} [newBinderParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public newBinder(id: string, newBinderParamsBody?: NewBinderParamsBody, options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).newBinder(id, newBinderParamsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary フォルダーを新規作成する
     * @param {NewBinderFolderParamsBody} [newBinderFolderParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public newBinderFolder(newBinderFolderParamsBody?: NewBinderFolderParamsBody, options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).newBinderFolder(newBinderFolderParamsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary バインダーに新たなハイライトを登録する
     * @param {string} binderId ハイライトを登録する先のバインダー ID
     * @param {NewHighlightParamsBody} [newHighlightParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public newHighlight(binderId: string, newHighlightParamsBody?: NewHighlightParamsBody, options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).newHighlight(binderId, newHighlightParamsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary バインダーに新たな PDF ビューのブックマークを登録する
     * @param {string} binderId ブックマークを登録する先のバインダー ID
     * @param {NewPdfBookmarkParamsBody} [newPdfBookmarkParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public newPdfBookmark(binderId: string, newPdfBookmarkParamsBody?: NewPdfBookmarkParamsBody, options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).newPdfBookmark(binderId, newPdfBookmarkParamsBody, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary バインダーに新たなブックマークを登録する
     * @param {string} binderId ブックマークを登録する先のバインダー ID
     * @param {NewWebBookmarkParamsBody} [newWebBookmarkParamsBody] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof BindersApi
     */
    public newWebBookmark(binderId: string, newWebBookmarkParamsBody?: NewWebBookmarkParamsBody, options?: AxiosRequestConfig) {
        return BindersApiFp(this.configuration).newWebBookmark(binderId, newWebBookmarkParamsBody, options).then((request) => request(this.axios, this.basePath));
    }
}
