/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { CountsByKey } from '../model';
// @ts-ignore
import { GetPracticeAreaSnippetsResponse } from '../model';
// @ts-ignore
import { NewsCategory } from '../model';
// @ts-ignore
import { NewsWithCategory } from '../model';
// @ts-ignore
import { TotalCount } from '../model';
/**
 * NewsApi - axios parameter creator
 * @export
 */
export const NewsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ニュースを作成日降順で取得する
         * @param {string} [categoryId] ニュースの所属カテゴリ。指定しない場合は全カテゴリのニュースから取得する
         * @param {number} [offset] 取得するニュースのオフセット
         * @param {number} [limit] 取得するニュースの最大数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNews: async (categoryId?: string, offset?: number, limit?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/news`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (categoryId !== undefined) {
                localVarQueryParameter['categoryId'] = categoryId;
            }

            if (offset !== undefined) {
                localVarQueryParameter['offset'] = offset;
            }

            if (limit !== undefined) {
                localVarQueryParameter['limit'] = limit;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ニュースのカテゴリ一覧をデフォルト順ですべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/news/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ニュースの全数カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsCount: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/news/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * ニュースのカテゴリ別カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsCountByCategory: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/news/categories/count`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
         * @summary プラクティスエリアの各種リソースのスニペットを取得する。
         * @param {string} id プラクティスエリア ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeAreaSnippets: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPracticeAreaSnippets', 'id', id)
            const localVarPath = `/practice-areas/{id}/snippets`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * NewsApi - functional programming interface
 * @export
 */
export const NewsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = NewsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ニュースを作成日降順で取得する
         * @param {string} [categoryId] ニュースの所属カテゴリ。指定しない場合は全カテゴリのニュースから取得する
         * @param {number} [offset] 取得するニュースのオフセット
         * @param {number} [limit] 取得するニュースの最大数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNews(categoryId?: string, offset?: number, limit?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsWithCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNews(categoryId, offset, limit, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ニュースのカテゴリ一覧をデフォルト順ですべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<NewsCategory>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ニュースの全数カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsCount(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<TotalCount>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsCount(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * ニュースのカテゴリ別カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getNewsCountByCategory(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CountsByKey>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getNewsCountByCategory(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
         * @summary プラクティスエリアの各種リソースのスニペットを取得する。
         * @param {string} id プラクティスエリア ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeAreaSnippets(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPracticeAreaSnippetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPracticeAreaSnippets(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * NewsApi - factory interface
 * @export
 */
export const NewsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = NewsApiFp(configuration)
    return {
        /**
         * 
         * @summary ニュースを作成日降順で取得する
         * @param {string} [categoryId] ニュースの所属カテゴリ。指定しない場合は全カテゴリのニュースから取得する
         * @param {number} [offset] 取得するニュースのオフセット
         * @param {number} [limit] 取得するニュースの最大数
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNews(categoryId?: string, offset?: number, limit?: number, options?: any): AxiosPromise<Array<NewsWithCategory>> {
            return localVarFp.getNews(categoryId, offset, limit, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ニュースのカテゴリ一覧をデフォルト順ですべて取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsCategories(options?: any): AxiosPromise<Array<NewsCategory>> {
            return localVarFp.getNewsCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * ニュースの全数カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsCount(options?: any): AxiosPromise<TotalCount> {
            return localVarFp.getNewsCount(options).then((request) => request(axios, basePath));
        },
        /**
         * ニュースのカテゴリ別カウント
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNewsCountByCategory(options?: any): AxiosPromise<CountsByKey> {
            return localVarFp.getNewsCountByCategory(options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
         * @summary プラクティスエリアの各種リソースのスニペットを取得する。
         * @param {string} id プラクティスエリア ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeAreaSnippets(id: string, options?: any): AxiosPromise<GetPracticeAreaSnippetsResponse> {
            return localVarFp.getPracticeAreaSnippets(id, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * NewsApi - interface
 * @export
 * @interface NewsApi
 */
export interface NewsApiInterface {
    /**
     * 
     * @summary ニュースを作成日降順で取得する
     * @param {string} [categoryId] ニュースの所属カテゴリ。指定しない場合は全カテゴリのニュースから取得する
     * @param {number} [offset] 取得するニュースのオフセット
     * @param {number} [limit] 取得するニュースの最大数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    getNews(categoryId?: string, offset?: number, limit?: number, options?: AxiosRequestConfig): AxiosPromise<Array<NewsWithCategory>>;

    /**
     * 
     * @summary ニュースのカテゴリ一覧をデフォルト順ですべて取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    getNewsCategories(options?: AxiosRequestConfig): AxiosPromise<Array<NewsCategory>>;

    /**
     * ニュースの全数カウント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    getNewsCount(options?: AxiosRequestConfig): AxiosPromise<TotalCount>;

    /**
     * ニュースのカテゴリ別カウント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    getNewsCountByCategory(options?: AxiosRequestConfig): AxiosPromise<CountsByKey>;

    /**
     * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
     * @summary プラクティスエリアの各種リソースのスニペットを取得する。
     * @param {string} id プラクティスエリア ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApiInterface
     */
    getPracticeAreaSnippets(id: string, options?: AxiosRequestConfig): AxiosPromise<GetPracticeAreaSnippetsResponse>;

}

/**
 * NewsApi - object-oriented interface
 * @export
 * @class NewsApi
 * @extends {BaseAPI}
 */
export class NewsApi extends BaseAPI implements NewsApiInterface {
    /**
     * 
     * @summary ニュースを作成日降順で取得する
     * @param {string} [categoryId] ニュースの所属カテゴリ。指定しない場合は全カテゴリのニュースから取得する
     * @param {number} [offset] 取得するニュースのオフセット
     * @param {number} [limit] 取得するニュースの最大数
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public getNews(categoryId?: string, offset?: number, limit?: number, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).getNews(categoryId, offset, limit, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ニュースのカテゴリ一覧をデフォルト順ですべて取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public getNewsCategories(options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).getNewsCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ニュースの全数カウント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public getNewsCount(options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).getNewsCount(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * ニュースのカテゴリ別カウント
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public getNewsCountByCategory(options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).getNewsCountByCategory(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
     * @summary プラクティスエリアの各種リソースのスニペットを取得する。
     * @param {string} id プラクティスエリア ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof NewsApi
     */
    public getPracticeAreaSnippets(id: string, options?: AxiosRequestConfig) {
        return NewsApiFp(this.configuration).getPracticeAreaSnippets(id, options).then((request) => request(this.axios, this.basePath));
    }
}
