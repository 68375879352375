import type { PublicCommentsApi } from '../apis';
import { CacheTTL } from '../constants';
import type { KeyCount } from '../models';

export class PublicCommentsRepository {
  constructor(private api: PublicCommentsApi) {}
  async getCount(): Promise<number> {
    const { data } = await this.api.getPublicCommentCount({
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return data.count;
  }
  async getCountByPublisher(): Promise<KeyCount[]> {
    const { data } = await this.api.getPublicCommentCountsByPublisher({
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return data.counts;
  }
}
