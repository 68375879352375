<template>
  <div class="guideline-panel">
    <QueryOption label="タイトル" :query="guidelineQuery.title" @update:query="updateTitle" />
    <MaxGapOption :max-gaps="guidelineQuery.maxGaps" @update:max-gaps="updateMaxGaps" />
    <PublisherOption doctype="guideline" :publisher="guidelineQuery.publisher" @update:publisher="updatePublisher" />
    <ExcludeOldEditionOption
      :exclude-old-edition="!guidelineQuery.includeOlderEditions"
      @update:exclude-old-edition="updateExcludeOldEdition"
    />
    <DateOption :published-on="guidelineQuery.publishedOn" @update:published-on="updatePublishedOn" />
    <div class="guideline-panel__options">
      <PerPageOption />
      <SortOption />
    </div>
  </div>
</template>

<script lang="ts" setup>
import QueryOption from './options/query-option.vue';
import MaxGapOption from './options/max-gap-option.vue';
import PublisherOption from './options/publisher-option.vue';
import ExcludeOldEditionOption from './options/exclude-old-edition-option.vue';
import DateOption from './options/date-option.vue';
import PerPageOption from './options/per-page-option.vue';
import SortOption from './options/sort-option.vue';
import { PartialSearchQuery } from '@/types/SearchQuery';

export type GuidelinePanelEmits = {
  (e: 'update:guidelineQuery', value: PartialSearchQuery): void;
};

const emit = defineEmits<GuidelinePanelEmits>();

const props = defineProps<{
  guidelineQuery: PartialSearchQuery;
}>();

const updateTitle = (value: string) => {
  emit('update:guidelineQuery', { ...props.guidelineQuery, title: value });
};

const updateMaxGaps = (value: number) => {
  emit('update:guidelineQuery', { ...props.guidelineQuery, maxGaps: value });
};

const updatePublisher = (value: string[]) => {
  emit('update:guidelineQuery', { ...props.guidelineQuery, publisher: value });
};

const updatePublishedOn = (value: PartialSearchQuery['publishedOn']) => {
  emit('update:guidelineQuery', { ...props.guidelineQuery, publishedOn: value });
};

const updateExcludeOldEdition = (value: boolean) => {
  emit('update:guidelineQuery', { ...props.guidelineQuery, includeOlderEditions: !value });
};
</script>

<style lang="scss" scoped src="./guideline-panel.scss" />
