<template>
  <i :class="iconClasses" @click="$emit('click', $event)" />
</template>

<script lang="ts" setup>
import { computed } from 'vue';

export interface IconProps {
  name:
    | 'ai'
    | 'down'
    | 'return'
    | 'next'
    | 'setting'
    | 'binder'
    | 'quickaccess'
    | 'book-list'
    | 'filter-reset'
    | 'support'
    | 'manual'
    | 'history'
    | 'my-collection'
    | 'account-circle'
    | 'format-file'
    | 'pubcom'
    | 'guideline'
    | 'news'
    | 'book'
    | 'calendar'
    | 'filter-close'
    | 'filter-open'
    | 'help-circle'
    | 'bell-circle'
    | 'search'
    | 'keyword'
    | 'menu'
    | 'share'
    | 'calendar-edit'
    | 'account-tie'
    | 'office-building'
    | 'finance'
    | 'domain'
    | 'diamond-stone'
    | 'alpha-a-circle'
    | 'alpha-b-circle'
    | 'alpha-c-circle'
    | 'open-in-new'
    | 'pdf-box'
    | 'law'
    | 'precedent'
    | 'cart-outline'
    | 'case'
    | 'account-box'
    | 'logout'
    | 'handle'
    | 'chevron-double-right'
    | 'close';
  size: 'xs' | 'sm' | 'md' | '2md' | 'lg' | 'xl' | '2xl';
  color:
    | 'gray-0'
    | 'gray-50'
    | 'gray-100'
    | 'gray-200'
    | 'gray-300'
    | 'gray-400'
    | 'gray-600'
    | 'blue-50'
    | 'blue-100'
    | 'blue-200'
    | 'blue-300'
    | 'blue-400'
    | 'blue-500'
    | 'blue-600'
    | 'orange-50'
    | 'orange-100'
    | 'red-50'
    | 'red-100';
}

const props = defineProps<IconProps>();

const iconClasses = computed(() => {
  return ['icon', `icon--${props.name}`, `icon--${props.size}`, `icon--${props.color}`];
});

export type IconEmits = {
  click: [event: Event];
};

defineEmits<IconEmits>();
</script>

<style lang="scss" src="./icon.scss" scoped></style>
