import { Organization } from 'wklr-backend-sdk/models';

/**
 * 組織が本利用期間中かどうかを判定する
 * @param organization 組織情報
 * @param now 現在日時（デフォルト値 new Date()）
 * @returns 組織が本利用期間中であればtrue、そうでなければfalse
 */
export function isInPaidPeriod(organization: Organization, now: Date = new Date()): boolean {
  // paidFromは過去、paidUntilは未来または未指定の場合に本利用期間中と判断
  return (
    organization.paidFrom !== null &&
    new Date(organization.paidFrom) <= now &&
    (organization.paidUntil === null || new Date(organization.paidUntil) > now)
  );
}
