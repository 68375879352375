<template>
  <v-list two-line class="account-menu-list">
    <v-list-item to="/profile" class="account-menu-list__item">
      <v-list-item-content class="account-menu-list__item__content">
        <div class="account-menu-list__item__content__inner">
          <v-list-item-title class="account-menu-list__item__title">
            <icon name="account-box" color="gray-300" size="md" />
            プロフィール
          </v-list-item-title>
        </div>
        <icon name="next" color="gray-300" size="sm" class="account-menu-list__item__content__next" />
      </v-list-item-content>
    </v-list-item>
    <v-list-item class="account-menu-list__item" @click="logout">
      <v-list-item-content class="account-menu-list__item__content">
        <div class="account-menu-list__item__content__inner">
          <v-list-item-title class="account-menu-list__item__title">
            <icon name="logout" color="gray-300" size="md" />
            ログアウト
          </v-list-item-title>
        </div>
      </v-list-item-content>
    </v-list-item>
  </v-list>
</template>

<script lang="ts" setup>
import { useRepositories } from '@/plugins/repositories';
import Icon from '@/components/renewal/common/icon.vue';

const $repositories = useRepositories();

const deleteSessionDevice = async (): Promise<void> => {
  try {
    await $repositories.devices.deleteSessionDevice();
  } catch (error) {
    console.log('セッションの端末を削除できませんでした');
    console.log(error);
  }
};

const logout = async (): Promise<void> => {
  await deleteSessionDevice();
  location.href = '/auth/logout';
};
</script>

<style lang="scss" scoped src="./account-menu-list.scss"></style>
