<template>
  <div class="publisher-option">
    <label for="publisher-option">発行元</label>
    <div class="publisher-option__value">
      <v-menu offset-y :close-on-content-click="false" content-class="publisher-option__menu-wrap">
        <template #activator="{ on, attrs }">
          <button type="button" class="publisher-option__select" v-bind="attrs" v-on="on">
            <div class="publisher-option__select__text">
              <span v-if="isSelectedAll">すべて</span>
              <template v-else>
                <span v-for="(p, index) in publisher" :key="index">{{ p }}</span>
              </template>
            </div>
            <!-- TODO: カウントの実装 -->
            <!-- <span class="publisher-option__count">(1000)</span> -->
            <Icon class="publisher-option__select__icon" name="down" size="sm" color="gray-400" />
          </button>
        </template>
        <div class="publisher-option__menu">
          <div class="publisher-option__menu__search">
            <Icon name="search" size="sm" color="gray-300" />
            <input v-model="searchText" type="text" />
          </div>
          <div class="publisher-option__menu__items">
            <label
              v-for="(filteredPublisher, index) in filteredPublishers"
              :key="index"
              class="publisher-option__menu__item"
            >
              <input
                type="checkbox"
                :value="filteredPublisher.name"
                :checked="isSelected(filteredPublisher.name)"
                @change="handleChangePublisher(filteredPublisher.name, $event)"
              />
              <span>{{ filteredPublisher.name }}</span>
              <!-- TODO: カウントの実装 -->
              <!-- <span class="publisher-option__count">({{ publisher.count }})</span> -->
            </label>
          </div>
        </div>
      </v-menu>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onBeforeMount } from 'vue';
import Icon from '@/components/renewal/common/icon.vue';
import { useRepositories } from '@/plugins/repositories';

const props = defineProps<{
  doctype: 'book' | 'guideline' | 'pubcom';
  publisher?: string[];
}>();

export type PublisherOptionEmits = {
  (e: 'update:publisher', publisher: string[]): void;
};

const emit = defineEmits<PublisherOptionEmits>();

const repositories = useRepositories();

const publishers = ref<{ name: string }[]>([]);
const searchText = ref('');

const filteredPublishers = computed(() => {
  const searchTextLower = searchText.value.toLowerCase();
  return publishers.value.filter((p) => p.name.toLowerCase().includes(searchTextLower));
});

const isSelectedAll = computed(() => {
  return props.publisher === undefined || props.publisher.length === 0;
});

const isSelected = (publisher: string) => {
  return props.publisher?.includes(publisher) ?? false;
};

const handleChangePublisher = (publisher: string, event: Event) => {
  const target = event.currentTarget as HTMLInputElement;
  if (target.checked) {
    emit('update:publisher', [...(props.publisher ?? []), publisher]);
  } else {
    emit('update:publisher', props.publisher?.filter((p) => p !== publisher) ?? []);
  }
};

onBeforeMount(async () => {
  const publisherNames = await repositories.publishers.listPublisherNames(props.doctype);
  publishers.value = publisherNames.map((name) => ({ name }));
});
</script>

<style lang="scss" src="./publisher-option.scss" scoped></style>
