<template>
  <v-btn v-if="enabled" icon small class="navigation-announce">
    <Icon name="bell-circle" size="lg" color="gray-0" class="site-header__icon" />
    <AnnounceKit catch-click=".navigation-announce" :widget="widgetUri" :widget-style="style" />
  </v-btn>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import AnnounceKit from 'announcekit-vue';
import { AnnounceKit as AnnounceKitConstants } from '@/constants';
import Icon from '@/components/renewal/common/icon.vue';

const widgetUri = computed(() => `https://announcekit.co/widgets/v2/${AnnounceKitConstants.WidgetToken}`);

const style = computed(() => ({
  position: 'absolute',
  top: '-4px',
  right: '0px',
}));

const enabled = computed(() => AnnounceKitConstants.Enabled);
</script>

<style lang="scss" scoped src="./navigation-announce.scss"></style>
