<template>
  <div class="query-option">
    <label for="query-option">{{ label }}</label>
    <!-- パスワードマネージャを無効にするためにdata-1p-ignore,data-lpignore,autocomplete=offを追加している -->
    <input
      id="query-option"
      v-model="queryValue"
      type="text"
      :name="label"
      class="query-option__value"
      data-1p-ignore
      data-lpignore
      autocomplete="off"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

const props = defineProps<{
  label: string;
  query?: string;
}>();

export type QueryOptionEmits = {
  (e: 'update:query', value: string): void;
};

const emit = defineEmits<QueryOptionEmits>();
const queryValue = ref(props.query || '');

watch(
  () => props.query,
  (newQuery) => {
    queryValue.value = newQuery || '';
  },
);

watch(queryValue, (newValue) => {
  emit('update:query', newValue);
});
</script>

<style lang="scss" src="./query-option.scss" scoped />
