/* tslint:disable */
/* eslint-disable */
/**
 * Legalscape REST API
 * Internal API for use from web
 *
 * The version of the OpenAPI document: 2.0.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosPromise, AxiosInstance, AxiosRequestConfig } from 'axios';
import { Configuration } from '../configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from '../common';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, RequestArgs, BaseAPI, RequiredError } from '../base';
// @ts-ignore
import { BinderItemOfDocument } from '../model';
// @ts-ignore
import { DocCategoriesResult } from '../model';
// @ts-ignore
import { DocRecord } from '../model';
// @ts-ignore
import { DocRecordTiny } from '../model';
// @ts-ignore
import { FeaturedDocRecord } from '../model';
// @ts-ignore
import { GetDocumentAccessibility200Response } from '../model';
// @ts-ignore
import { GetDocumentAvailability200Response } from '../model';
// @ts-ignore
import { GetDocumentPdfResponse } from '../model';
// @ts-ignore
import { GetPracticeAreaSnippetsResponse } from '../model';
// @ts-ignore
import { GetTaggedDocumentsResponse } from '../model';
// @ts-ignore
import { LookupResult } from '../model';
// @ts-ignore
import { PublicDocRecord } from '../model';
// @ts-ignore
import { QuickAccessItemsOfDocument } from '../model';
/**
 * DocumentsApi - axios parameter creator
 * @export
 */
export const DocumentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary ID で指定された文献に設定されているブックマークやハイライトを取得する
         * @param {string} naturalId 文献の ID (書籍の ISBN など)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBinderItemsOfDocument: async (naturalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'naturalId' is not null or undefined
            assertParamExists('getBinderItemsOfDocument', 'naturalId', naturalId)
            const localVarPath = `/documents/{naturalId}/binder-items`
                .replace(`{${"naturalId"}}`, encodeURIComponent(String(naturalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * document を分類するためのカテゴリをすべて返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocCategories: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/doc-categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * IDで指定されたドキュメントを取得する。取得できる書籍はプラン内容によって変化する。
         * @summary IDで指定された書籍を取得する
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocument', 'id', id)
            const localVarPath = `/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * IDで指定されたドキュメントが閲覧可能であるかを真偽値で取得する。 ドキュメントが存在しなければ404を返す。 ドキュメントが存在すれば、accessibleかどうかとpurchasedかどうかを返す。 ドキュメントの具体的内容が欲しい場合は `/documents/{id}` を推奨。 typoに注意。 
         * @summary IDで指定されたドキュメントが閲覧可能であるかを取得する。
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentAccessibility: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentAccessibility', 'id', id)
            const localVarPath = `/documents/{id}/accessibility`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * IDで指定されたドキュメントが取得可能であるかを真偽値で取得する。 ドキュメントが存在し、利用可能であれば true を返す。 ドキュメント不存在の場合と利用不可の場合はどちらも false を返す。 500 以外は常に 200 を返す。 ドキュメントの具体的内容が欲しい場合は `/documents/{id}` を推奨。 
         * @summary IDで指定されたドキュメントが取得可能であるかを取得する。
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentAvailability: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentAvailability', 'id', id)
            const localVarPath = `/documents/{id}/availability`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * IDで指定されたドキュメントを取得する。（書誌情報のみ）
         * @summary IDで指定された書籍を取得する
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPublic: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentPublic', 'id', id)
            const localVarPath = `/public/documents/{id}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * IDで指定された資料のPDFデータを取得する。取得できる資料はプラン内容によって変化する。
         * @summary IDで指定された資料のPDFデータにアクセスするURLを取得する
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsPdfUrl: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getDocumentsPdfUrl', 'id', id)
            const localVarPath = `/documents/{id}/pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 資料から指定された複数のページを抽出し、所有者情報や発効日、紙面に透かしのロゴなどを追加してPDFデータとして返却する。
         * @summary 資料から指定された複数のページをPDFデータとして抽出する
         * @param {string} id 
         * @param {string} pageSeqs 抽出するページ番号が含まれた配列
         * @param {0 | 1} withColophon 奥付を含めるかどうかのフラグ
         * @param {string} [host] リクエスト元のホスト名
         * @param {string} [download] レスポンスが添付ファイルとして保存されるべきものかどうかを指定するためのフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExtractedPagesAsPdf: async (id: string, pageSeqs: string, withColophon: 0 | 1, host?: string, download?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getExtractedPagesAsPdf', 'id', id)
            // verify required parameter 'pageSeqs' is not null or undefined
            assertParamExists('getExtractedPagesAsPdf', 'pageSeqs', pageSeqs)
            // verify required parameter 'withColophon' is not null or undefined
            assertParamExists('getExtractedPagesAsPdf', 'withColophon', withColophon)
            const localVarPath = `/documents/{id}/extract-pdf`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (pageSeqs !== undefined) {
                localVarQueryParameter['pageSeqs'] = pageSeqs;
            }

            if (withColophon !== undefined) {
                localVarQueryParameter['withColophon'] = withColophon;
            }

            if (download !== undefined) {
                localVarQueryParameter['download'] = download;
            }

            if (host !== undefined && host !== null) {
                localVarHeaderParameter['host'] = String(host);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 注目書籍取得API
         * @summary DBの注目書籍リストを全件取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedDocumentsLists: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/documents/featured`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 人気の書籍を配列で取得する。書籍の表示には優先度があり、配列の最大値は30。
         * @summary 人気の書籍一覧を最大で30冊取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopularDocuments: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/popular-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
         * @summary プラクティスエリアの各種リソースのスニペットを取得する。
         * @param {string} id プラクティスエリア ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeAreaSnippets: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getPracticeAreaSnippets', 'id', id)
            const localVarPath = `/practice-areas/{id}/snippets`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary ID で指定された文献に設定されているクイックアクセスを取得する
         * @param {string} naturalId 文献の ID (書籍の ISBN など)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuickAccessItemsOfDocument: async (naturalId: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'naturalId' is not null or undefined
            assertParamExists('getQuickAccessItemsOfDocument', 'naturalId', naturalId)
            const localVarPath = `/documents/{naturalId}/quick-access-items`
                .replace(`{${"naturalId"}}`, encodeURIComponent(String(naturalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 最近追加された書籍の配列を返す。
         * @summary 最近追加された書籍一覧を取得する
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentlyAddedDocuments: async (from?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recently-added-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 最近追加された書籍の配列を返す。
         * @summary 最近追加された Store で購入可能なコンテンツの一覧を取得する
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentlyAddedPurchasableDocuments: async (from?: number, size?: number, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/recently-added-purchasable-documents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (from !== undefined) {
                localVarQueryParameter['from'] = from;
            }

            if (size !== undefined) {
                localVarQueryParameter['size'] = size;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 資料の1ページを抽出し、所有者情報や発効日、紙面に透かしのロゴなどを追加してPDFデータとして返却する。
         * @summary 資料から指定されたページをPDFデータとして抽出する
         * @param {string} id 
         * @param {number} pageSeq 抽出するページ番号
         * @param {string} [host] リクエスト元のホスト名
         * @param {string} [download] レスポンスが添付ファイルとして保存されるべきものかどうかを指定するためのフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePagePDF: async (id: string, pageSeq: number, host?: string, download?: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getSinglePagePDF', 'id', id)
            // verify required parameter 'pageSeq' is not null or undefined
            assertParamExists('getSinglePagePDF', 'pageSeq', pageSeq)
            const localVarPath = `/documents/{id}/single-page-pdf/{pageSeq}`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)))
                .replace(`{${"pageSeq"}}`, encodeURIComponent(String(pageSeq)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (download !== undefined) {
                localVarQueryParameter['download'] = download;
            }

            if (host !== undefined && host !== null) {
                localVarHeaderParameter['host'] = String(host);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 一部の資料にはタグが付与されており、この API ではそのタグを ID 指定することで該当する資料を返却する。 この API のレスポンスには、書誌情報しか存在していない (すなわち Legalscape には未掲載の) 書籍も含まれる。
         * @summary ID で指定されたタグが付与されている資料を取得する。
         * @param {string} id タグの ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaggedDocuments: async (id: string, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('getTaggedDocuments', 'id', id)
            const localVarPath = `/tags/{id}/documents`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * IDで指定したドキュメント内に、検索キーワードにヒットする箇所があるかどうかを検索して結果を返す
         * @summary 指定したドキュメント内にあるキーワードがあるかどうかを検索して返す
         * @param {string} id 
         * @param {string} [keyword] 対象のドキュメント内を検索するキーワード
         * @param {boolean} [accumulateSubsections] サブセクション以下のヒット件数の累計値を計算して返却するか否か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupInDocByKeyword: async (id: string, keyword?: string, accumulateSubsections?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'id' is not null or undefined
            assertParamExists('lookupInDocByKeyword', 'id', id)
            const localVarPath = `/documents/{id}/lookup`
                .replace(`{${"id"}}`, encodeURIComponent(String(id)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (keyword !== undefined) {
                localVarQueryParameter['keyword'] = keyword;
            }

            if (accumulateSubsections !== undefined) {
                localVarQueryParameter['accumulateSubsections'] = accumulateSubsections;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * IDで指定したドキュメント内に、検索キーワードにヒットする箇所があるかどうかを検索して結果を返す
         * @summary 指定したドキュメント内にあるキーワードがあるかどうかを検索して返す
         * @param {Array<string>} naturalId 
         * @param {Array<string>} [keywords] 対象のドキュメント内を検索するキーワード
         * @param {boolean} [accumulateSubsections] サブセクション以下のヒット件数の累計値を計算して返却するか否か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupInDocsByKeyword: async (naturalId: Array<string>, keywords?: Array<string>, accumulateSubsections?: boolean, options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'naturalId' is not null or undefined
            assertParamExists('lookupInDocsByKeyword', 'naturalId', naturalId)
            const localVarPath = `/documents/lookup`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (naturalId) {
                localVarQueryParameter['naturalId'] = naturalId;
            }

            if (keywords) {
                localVarQueryParameter['keywords'] = keywords;
            }

            if (accumulateSubsections !== undefined) {
                localVarQueryParameter['accumulateSubsections'] = accumulateSubsections;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DocumentsApi - functional programming interface
 * @export
 */
export const DocumentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DocumentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary ID で指定された文献に設定されているブックマークやハイライトを取得する
         * @param {string} naturalId 文献の ID (書籍の ISBN など)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getBinderItemsOfDocument(naturalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BinderItemOfDocument>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getBinderItemsOfDocument(naturalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * document を分類するためのカテゴリをすべて返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocCategories(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocCategoriesResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocCategories(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * IDで指定されたドキュメントを取得する。取得できる書籍はプラン内容によって変化する。
         * @summary IDで指定された書籍を取得する
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocument(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DocRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocument(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * IDで指定されたドキュメントが閲覧可能であるかを真偽値で取得する。 ドキュメントが存在しなければ404を返す。 ドキュメントが存在すれば、accessibleかどうかとpurchasedかどうかを返す。 ドキュメントの具体的内容が欲しい場合は `/documents/{id}` を推奨。 typoに注意。 
         * @summary IDで指定されたドキュメントが閲覧可能であるかを取得する。
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentAccessibility(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentAccessibility200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentAccessibility(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * IDで指定されたドキュメントが取得可能であるかを真偽値で取得する。 ドキュメントが存在し、利用可能であれば true を返す。 ドキュメント不存在の場合と利用不可の場合はどちらも false を返す。 500 以外は常に 200 を返す。 ドキュメントの具体的内容が欲しい場合は `/documents/{id}` を推奨。 
         * @summary IDで指定されたドキュメントが取得可能であるかを取得する。
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentAvailability(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentAvailability200Response>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentAvailability(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * IDで指定されたドキュメントを取得する。（書誌情報のみ）
         * @summary IDで指定された書籍を取得する
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentPublic(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PublicDocRecord>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentPublic(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * IDで指定された資料のPDFデータを取得する。取得できる資料はプラン内容によって変化する。
         * @summary IDで指定された資料のPDFデータにアクセスするURLを取得する
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getDocumentsPdfUrl(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetDocumentPdfResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getDocumentsPdfUrl(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 資料から指定された複数のページを抽出し、所有者情報や発効日、紙面に透かしのロゴなどを追加してPDFデータとして返却する。
         * @summary 資料から指定された複数のページをPDFデータとして抽出する
         * @param {string} id 
         * @param {string} pageSeqs 抽出するページ番号が含まれた配列
         * @param {0 | 1} withColophon 奥付を含めるかどうかのフラグ
         * @param {string} [host] リクエスト元のホスト名
         * @param {string} [download] レスポンスが添付ファイルとして保存されるべきものかどうかを指定するためのフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getExtractedPagesAsPdf(id: string, pageSeqs: string, withColophon: 0 | 1, host?: string, download?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getExtractedPagesAsPdf(id, pageSeqs, withColophon, host, download, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 注目書籍取得API
         * @summary DBの注目書籍リストを全件取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFeaturedDocumentsLists(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FeaturedDocRecord>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFeaturedDocumentsLists(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 人気の書籍を配列で取得する。書籍の表示には優先度があり、配列の最大値は30。
         * @summary 人気の書籍一覧を最大で30冊取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPopularDocuments(options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocRecordTiny>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPopularDocuments(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
         * @summary プラクティスエリアの各種リソースのスニペットを取得する。
         * @param {string} id プラクティスエリア ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPracticeAreaSnippets(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetPracticeAreaSnippetsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPracticeAreaSnippets(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 
         * @summary ID で指定された文献に設定されているクイックアクセスを取得する
         * @param {string} naturalId 文献の ID (書籍の ISBN など)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getQuickAccessItemsOfDocument(naturalId: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<QuickAccessItemsOfDocument>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getQuickAccessItemsOfDocument(naturalId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 最近追加された書籍の配列を返す。
         * @summary 最近追加された書籍一覧を取得する
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecentlyAddedDocuments(from?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocRecordTiny>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecentlyAddedDocuments(from, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 最近追加された書籍の配列を返す。
         * @summary 最近追加された Store で購入可能なコンテンツの一覧を取得する
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getRecentlyAddedPurchasableDocuments(from?: number, size?: number, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DocRecordTiny>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getRecentlyAddedPurchasableDocuments(from, size, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 資料の1ページを抽出し、所有者情報や発効日、紙面に透かしのロゴなどを追加してPDFデータとして返却する。
         * @summary 資料から指定されたページをPDFデータとして抽出する
         * @param {string} id 
         * @param {number} pageSeq 抽出するページ番号
         * @param {string} [host] リクエスト元のホスト名
         * @param {string} [download] レスポンスが添付ファイルとして保存されるべきものかどうかを指定するためのフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getSinglePagePDF(id: string, pageSeq: number, host?: string, download?: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getSinglePagePDF(id, pageSeq, host, download, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 一部の資料にはタグが付与されており、この API ではそのタグを ID 指定することで該当する資料を返却する。 この API のレスポンスには、書誌情報しか存在していない (すなわち Legalscape には未掲載の) 書籍も含まれる。
         * @summary ID で指定されたタグが付与されている資料を取得する。
         * @param {string} id タグの ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTaggedDocuments(id: string, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<GetTaggedDocumentsResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTaggedDocuments(id, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * IDで指定したドキュメント内に、検索キーワードにヒットする箇所があるかどうかを検索して結果を返す
         * @summary 指定したドキュメント内にあるキーワードがあるかどうかを検索して返す
         * @param {string} id 
         * @param {string} [keyword] 対象のドキュメント内を検索するキーワード
         * @param {boolean} [accumulateSubsections] サブセクション以下のヒット件数の累計値を計算して返却するか否か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupInDocByKeyword(id: string, keyword?: string, accumulateSubsections?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<LookupResult>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupInDocByKeyword(id, keyword, accumulateSubsections, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * IDで指定したドキュメント内に、検索キーワードにヒットする箇所があるかどうかを検索して結果を返す
         * @summary 指定したドキュメント内にあるキーワードがあるかどうかを検索して返す
         * @param {Array<string>} naturalId 
         * @param {Array<string>} [keywords] 対象のドキュメント内を検索するキーワード
         * @param {boolean} [accumulateSubsections] サブセクション以下のヒット件数の累計値を計算して返却するか否か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async lookupInDocsByKeyword(naturalId: Array<string>, keywords?: Array<string>, accumulateSubsections?: boolean, options?: AxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<{ [key: string]: { [key: string]: LookupResult; }; }>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.lookupInDocsByKeyword(naturalId, keywords, accumulateSubsections, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * DocumentsApi - factory interface
 * @export
 */
export const DocumentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DocumentsApiFp(configuration)
    return {
        /**
         * 
         * @summary ID で指定された文献に設定されているブックマークやハイライトを取得する
         * @param {string} naturalId 文献の ID (書籍の ISBN など)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBinderItemsOfDocument(naturalId: string, options?: any): AxiosPromise<Array<BinderItemOfDocument>> {
            return localVarFp.getBinderItemsOfDocument(naturalId, options).then((request) => request(axios, basePath));
        },
        /**
         * document を分類するためのカテゴリをすべて返す
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocCategories(options?: any): AxiosPromise<DocCategoriesResult> {
            return localVarFp.getDocCategories(options).then((request) => request(axios, basePath));
        },
        /**
         * IDで指定されたドキュメントを取得する。取得できる書籍はプラン内容によって変化する。
         * @summary IDで指定された書籍を取得する
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocument(id: string, options?: any): AxiosPromise<DocRecord> {
            return localVarFp.getDocument(id, options).then((request) => request(axios, basePath));
        },
        /**
         * IDで指定されたドキュメントが閲覧可能であるかを真偽値で取得する。 ドキュメントが存在しなければ404を返す。 ドキュメントが存在すれば、accessibleかどうかとpurchasedかどうかを返す。 ドキュメントの具体的内容が欲しい場合は `/documents/{id}` を推奨。 typoに注意。 
         * @summary IDで指定されたドキュメントが閲覧可能であるかを取得する。
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentAccessibility(id: string, options?: any): AxiosPromise<GetDocumentAccessibility200Response> {
            return localVarFp.getDocumentAccessibility(id, options).then((request) => request(axios, basePath));
        },
        /**
         * IDで指定されたドキュメントが取得可能であるかを真偽値で取得する。 ドキュメントが存在し、利用可能であれば true を返す。 ドキュメント不存在の場合と利用不可の場合はどちらも false を返す。 500 以外は常に 200 を返す。 ドキュメントの具体的内容が欲しい場合は `/documents/{id}` を推奨。 
         * @summary IDで指定されたドキュメントが取得可能であるかを取得する。
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentAvailability(id: string, options?: any): AxiosPromise<GetDocumentAvailability200Response> {
            return localVarFp.getDocumentAvailability(id, options).then((request) => request(axios, basePath));
        },
        /**
         * IDで指定されたドキュメントを取得する。（書誌情報のみ）
         * @summary IDで指定された書籍を取得する
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentPublic(id: string, options?: any): AxiosPromise<PublicDocRecord> {
            return localVarFp.getDocumentPublic(id, options).then((request) => request(axios, basePath));
        },
        /**
         * IDで指定された資料のPDFデータを取得する。取得できる資料はプラン内容によって変化する。
         * @summary IDで指定された資料のPDFデータにアクセスするURLを取得する
         * @param {string} id 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDocumentsPdfUrl(id: string, options?: any): AxiosPromise<GetDocumentPdfResponse> {
            return localVarFp.getDocumentsPdfUrl(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 資料から指定された複数のページを抽出し、所有者情報や発効日、紙面に透かしのロゴなどを追加してPDFデータとして返却する。
         * @summary 資料から指定された複数のページをPDFデータとして抽出する
         * @param {string} id 
         * @param {string} pageSeqs 抽出するページ番号が含まれた配列
         * @param {0 | 1} withColophon 奥付を含めるかどうかのフラグ
         * @param {string} [host] リクエスト元のホスト名
         * @param {string} [download] レスポンスが添付ファイルとして保存されるべきものかどうかを指定するためのフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getExtractedPagesAsPdf(id: string, pageSeqs: string, withColophon: 0 | 1, host?: string, download?: string, options?: any): AxiosPromise<any> {
            return localVarFp.getExtractedPagesAsPdf(id, pageSeqs, withColophon, host, download, options).then((request) => request(axios, basePath));
        },
        /**
         * 注目書籍取得API
         * @summary DBの注目書籍リストを全件取得
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFeaturedDocumentsLists(options?: any): AxiosPromise<Array<FeaturedDocRecord>> {
            return localVarFp.getFeaturedDocumentsLists(options).then((request) => request(axios, basePath));
        },
        /**
         * 人気の書籍を配列で取得する。書籍の表示には優先度があり、配列の最大値は30。
         * @summary 人気の書籍一覧を最大で30冊取得する
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPopularDocuments(options?: any): AxiosPromise<Array<DocRecordTiny>> {
            return localVarFp.getPopularDocuments(options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
         * @summary プラクティスエリアの各種リソースのスニペットを取得する。
         * @param {string} id プラクティスエリア ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPracticeAreaSnippets(id: string, options?: any): AxiosPromise<GetPracticeAreaSnippetsResponse> {
            return localVarFp.getPracticeAreaSnippets(id, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary ID で指定された文献に設定されているクイックアクセスを取得する
         * @param {string} naturalId 文献の ID (書籍の ISBN など)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getQuickAccessItemsOfDocument(naturalId: string, options?: any): AxiosPromise<QuickAccessItemsOfDocument> {
            return localVarFp.getQuickAccessItemsOfDocument(naturalId, options).then((request) => request(axios, basePath));
        },
        /**
         * 最近追加された書籍の配列を返す。
         * @summary 最近追加された書籍一覧を取得する
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentlyAddedDocuments(from?: number, size?: number, options?: any): AxiosPromise<Array<DocRecordTiny>> {
            return localVarFp.getRecentlyAddedDocuments(from, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 最近追加された書籍の配列を返す。
         * @summary 最近追加された Store で購入可能なコンテンツの一覧を取得する
         * @param {number} [from] 検索結果の何件目から表示するのか
         * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRecentlyAddedPurchasableDocuments(from?: number, size?: number, options?: any): AxiosPromise<Array<DocRecordTiny>> {
            return localVarFp.getRecentlyAddedPurchasableDocuments(from, size, options).then((request) => request(axios, basePath));
        },
        /**
         * 資料の1ページを抽出し、所有者情報や発効日、紙面に透かしのロゴなどを追加してPDFデータとして返却する。
         * @summary 資料から指定されたページをPDFデータとして抽出する
         * @param {string} id 
         * @param {number} pageSeq 抽出するページ番号
         * @param {string} [host] リクエスト元のホスト名
         * @param {string} [download] レスポンスが添付ファイルとして保存されるべきものかどうかを指定するためのフラグ
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSinglePagePDF(id: string, pageSeq: number, host?: string, download?: string, options?: any): AxiosPromise<any> {
            return localVarFp.getSinglePagePDF(id, pageSeq, host, download, options).then((request) => request(axios, basePath));
        },
        /**
         * 一部の資料にはタグが付与されており、この API ではそのタグを ID 指定することで該当する資料を返却する。 この API のレスポンスには、書誌情報しか存在していない (すなわち Legalscape には未掲載の) 書籍も含まれる。
         * @summary ID で指定されたタグが付与されている資料を取得する。
         * @param {string} id タグの ID
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTaggedDocuments(id: string, options?: any): AxiosPromise<GetTaggedDocumentsResponse> {
            return localVarFp.getTaggedDocuments(id, options).then((request) => request(axios, basePath));
        },
        /**
         * IDで指定したドキュメント内に、検索キーワードにヒットする箇所があるかどうかを検索して結果を返す
         * @summary 指定したドキュメント内にあるキーワードがあるかどうかを検索して返す
         * @param {string} id 
         * @param {string} [keyword] 対象のドキュメント内を検索するキーワード
         * @param {boolean} [accumulateSubsections] サブセクション以下のヒット件数の累計値を計算して返却するか否か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupInDocByKeyword(id: string, keyword?: string, accumulateSubsections?: boolean, options?: any): AxiosPromise<LookupResult> {
            return localVarFp.lookupInDocByKeyword(id, keyword, accumulateSubsections, options).then((request) => request(axios, basePath));
        },
        /**
         * IDで指定したドキュメント内に、検索キーワードにヒットする箇所があるかどうかを検索して結果を返す
         * @summary 指定したドキュメント内にあるキーワードがあるかどうかを検索して返す
         * @param {Array<string>} naturalId 
         * @param {Array<string>} [keywords] 対象のドキュメント内を検索するキーワード
         * @param {boolean} [accumulateSubsections] サブセクション以下のヒット件数の累計値を計算して返却するか否か
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        lookupInDocsByKeyword(naturalId: Array<string>, keywords?: Array<string>, accumulateSubsections?: boolean, options?: any): AxiosPromise<{ [key: string]: { [key: string]: LookupResult; }; }> {
            return localVarFp.lookupInDocsByKeyword(naturalId, keywords, accumulateSubsections, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DocumentsApi - interface
 * @export
 * @interface DocumentsApi
 */
export interface DocumentsApiInterface {
    /**
     * 
     * @summary ID で指定された文献に設定されているブックマークやハイライトを取得する
     * @param {string} naturalId 文献の ID (書籍の ISBN など)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    getBinderItemsOfDocument(naturalId: string, options?: AxiosRequestConfig): AxiosPromise<Array<BinderItemOfDocument>>;

    /**
     * document を分類するためのカテゴリをすべて返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    getDocCategories(options?: AxiosRequestConfig): AxiosPromise<DocCategoriesResult>;

    /**
     * IDで指定されたドキュメントを取得する。取得できる書籍はプラン内容によって変化する。
     * @summary IDで指定された書籍を取得する
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    getDocument(id: string, options?: AxiosRequestConfig): AxiosPromise<DocRecord>;

    /**
     * IDで指定されたドキュメントが閲覧可能であるかを真偽値で取得する。 ドキュメントが存在しなければ404を返す。 ドキュメントが存在すれば、accessibleかどうかとpurchasedかどうかを返す。 ドキュメントの具体的内容が欲しい場合は `/documents/{id}` を推奨。 typoに注意。 
     * @summary IDで指定されたドキュメントが閲覧可能であるかを取得する。
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    getDocumentAccessibility(id: string, options?: AxiosRequestConfig): AxiosPromise<GetDocumentAccessibility200Response>;

    /**
     * IDで指定されたドキュメントが取得可能であるかを真偽値で取得する。 ドキュメントが存在し、利用可能であれば true を返す。 ドキュメント不存在の場合と利用不可の場合はどちらも false を返す。 500 以外は常に 200 を返す。 ドキュメントの具体的内容が欲しい場合は `/documents/{id}` を推奨。 
     * @summary IDで指定されたドキュメントが取得可能であるかを取得する。
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    getDocumentAvailability(id: string, options?: AxiosRequestConfig): AxiosPromise<GetDocumentAvailability200Response>;

    /**
     * IDで指定されたドキュメントを取得する。（書誌情報のみ）
     * @summary IDで指定された書籍を取得する
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    getDocumentPublic(id: string, options?: AxiosRequestConfig): AxiosPromise<PublicDocRecord>;

    /**
     * IDで指定された資料のPDFデータを取得する。取得できる資料はプラン内容によって変化する。
     * @summary IDで指定された資料のPDFデータにアクセスするURLを取得する
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    getDocumentsPdfUrl(id: string, options?: AxiosRequestConfig): AxiosPromise<GetDocumentPdfResponse>;

    /**
     * 資料から指定された複数のページを抽出し、所有者情報や発効日、紙面に透かしのロゴなどを追加してPDFデータとして返却する。
     * @summary 資料から指定された複数のページをPDFデータとして抽出する
     * @param {string} id 
     * @param {string} pageSeqs 抽出するページ番号が含まれた配列
     * @param {0 | 1} withColophon 奥付を含めるかどうかのフラグ
     * @param {string} [host] リクエスト元のホスト名
     * @param {string} [download] レスポンスが添付ファイルとして保存されるべきものかどうかを指定するためのフラグ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    getExtractedPagesAsPdf(id: string, pageSeqs: string, withColophon: 0 | 1, host?: string, download?: string, options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * 注目書籍取得API
     * @summary DBの注目書籍リストを全件取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    getFeaturedDocumentsLists(options?: AxiosRequestConfig): AxiosPromise<Array<FeaturedDocRecord>>;

    /**
     * 人気の書籍を配列で取得する。書籍の表示には優先度があり、配列の最大値は30。
     * @summary 人気の書籍一覧を最大で30冊取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    getPopularDocuments(options?: AxiosRequestConfig): AxiosPromise<Array<DocRecordTiny>>;

    /**
     * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
     * @summary プラクティスエリアの各種リソースのスニペットを取得する。
     * @param {string} id プラクティスエリア ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    getPracticeAreaSnippets(id: string, options?: AxiosRequestConfig): AxiosPromise<GetPracticeAreaSnippetsResponse>;

    /**
     * 
     * @summary ID で指定された文献に設定されているクイックアクセスを取得する
     * @param {string} naturalId 文献の ID (書籍の ISBN など)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    getQuickAccessItemsOfDocument(naturalId: string, options?: AxiosRequestConfig): AxiosPromise<QuickAccessItemsOfDocument>;

    /**
     * 最近追加された書籍の配列を返す。
     * @summary 最近追加された書籍一覧を取得する
     * @param {number} [from] 検索結果の何件目から表示するのか
     * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    getRecentlyAddedDocuments(from?: number, size?: number, options?: AxiosRequestConfig): AxiosPromise<Array<DocRecordTiny>>;

    /**
     * 最近追加された書籍の配列を返す。
     * @summary 最近追加された Store で購入可能なコンテンツの一覧を取得する
     * @param {number} [from] 検索結果の何件目から表示するのか
     * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    getRecentlyAddedPurchasableDocuments(from?: number, size?: number, options?: AxiosRequestConfig): AxiosPromise<Array<DocRecordTiny>>;

    /**
     * 資料の1ページを抽出し、所有者情報や発効日、紙面に透かしのロゴなどを追加してPDFデータとして返却する。
     * @summary 資料から指定されたページをPDFデータとして抽出する
     * @param {string} id 
     * @param {number} pageSeq 抽出するページ番号
     * @param {string} [host] リクエスト元のホスト名
     * @param {string} [download] レスポンスが添付ファイルとして保存されるべきものかどうかを指定するためのフラグ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    getSinglePagePDF(id: string, pageSeq: number, host?: string, download?: string, options?: AxiosRequestConfig): AxiosPromise<any>;

    /**
     * 一部の資料にはタグが付与されており、この API ではそのタグを ID 指定することで該当する資料を返却する。 この API のレスポンスには、書誌情報しか存在していない (すなわち Legalscape には未掲載の) 書籍も含まれる。
     * @summary ID で指定されたタグが付与されている資料を取得する。
     * @param {string} id タグの ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    getTaggedDocuments(id: string, options?: AxiosRequestConfig): AxiosPromise<GetTaggedDocumentsResponse>;

    /**
     * IDで指定したドキュメント内に、検索キーワードにヒットする箇所があるかどうかを検索して結果を返す
     * @summary 指定したドキュメント内にあるキーワードがあるかどうかを検索して返す
     * @param {string} id 
     * @param {string} [keyword] 対象のドキュメント内を検索するキーワード
     * @param {boolean} [accumulateSubsections] サブセクション以下のヒット件数の累計値を計算して返却するか否か
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    lookupInDocByKeyword(id: string, keyword?: string, accumulateSubsections?: boolean, options?: AxiosRequestConfig): AxiosPromise<LookupResult>;

    /**
     * IDで指定したドキュメント内に、検索キーワードにヒットする箇所があるかどうかを検索して結果を返す
     * @summary 指定したドキュメント内にあるキーワードがあるかどうかを検索して返す
     * @param {Array<string>} naturalId 
     * @param {Array<string>} [keywords] 対象のドキュメント内を検索するキーワード
     * @param {boolean} [accumulateSubsections] サブセクション以下のヒット件数の累計値を計算して返却するか否か
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApiInterface
     */
    lookupInDocsByKeyword(naturalId: Array<string>, keywords?: Array<string>, accumulateSubsections?: boolean, options?: AxiosRequestConfig): AxiosPromise<{ [key: string]: { [key: string]: LookupResult; }; }>;

}

/**
 * DocumentsApi - object-oriented interface
 * @export
 * @class DocumentsApi
 * @extends {BaseAPI}
 */
export class DocumentsApi extends BaseAPI implements DocumentsApiInterface {
    /**
     * 
     * @summary ID で指定された文献に設定されているブックマークやハイライトを取得する
     * @param {string} naturalId 文献の ID (書籍の ISBN など)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getBinderItemsOfDocument(naturalId: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getBinderItemsOfDocument(naturalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * document を分類するためのカテゴリをすべて返す
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getDocCategories(options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getDocCategories(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * IDで指定されたドキュメントを取得する。取得できる書籍はプラン内容によって変化する。
     * @summary IDで指定された書籍を取得する
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getDocument(id: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getDocument(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * IDで指定されたドキュメントが閲覧可能であるかを真偽値で取得する。 ドキュメントが存在しなければ404を返す。 ドキュメントが存在すれば、accessibleかどうかとpurchasedかどうかを返す。 ドキュメントの具体的内容が欲しい場合は `/documents/{id}` を推奨。 typoに注意。 
     * @summary IDで指定されたドキュメントが閲覧可能であるかを取得する。
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getDocumentAccessibility(id: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getDocumentAccessibility(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * IDで指定されたドキュメントが取得可能であるかを真偽値で取得する。 ドキュメントが存在し、利用可能であれば true を返す。 ドキュメント不存在の場合と利用不可の場合はどちらも false を返す。 500 以外は常に 200 を返す。 ドキュメントの具体的内容が欲しい場合は `/documents/{id}` を推奨。 
     * @summary IDで指定されたドキュメントが取得可能であるかを取得する。
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getDocumentAvailability(id: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getDocumentAvailability(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * IDで指定されたドキュメントを取得する。（書誌情報のみ）
     * @summary IDで指定された書籍を取得する
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getDocumentPublic(id: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getDocumentPublic(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * IDで指定された資料のPDFデータを取得する。取得できる資料はプラン内容によって変化する。
     * @summary IDで指定された資料のPDFデータにアクセスするURLを取得する
     * @param {string} id 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getDocumentsPdfUrl(id: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getDocumentsPdfUrl(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 資料から指定された複数のページを抽出し、所有者情報や発効日、紙面に透かしのロゴなどを追加してPDFデータとして返却する。
     * @summary 資料から指定された複数のページをPDFデータとして抽出する
     * @param {string} id 
     * @param {string} pageSeqs 抽出するページ番号が含まれた配列
     * @param {0 | 1} withColophon 奥付を含めるかどうかのフラグ
     * @param {string} [host] リクエスト元のホスト名
     * @param {string} [download] レスポンスが添付ファイルとして保存されるべきものかどうかを指定するためのフラグ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getExtractedPagesAsPdf(id: string, pageSeqs: string, withColophon: 0 | 1, host?: string, download?: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getExtractedPagesAsPdf(id, pageSeqs, withColophon, host, download, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 注目書籍取得API
     * @summary DBの注目書籍リストを全件取得
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getFeaturedDocumentsLists(options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getFeaturedDocumentsLists(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 人気の書籍を配列で取得する。書籍の表示には優先度があり、配列の最大値は30。
     * @summary 人気の書籍一覧を最大で30冊取得する
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getPopularDocuments(options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getPopularDocuments(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したプラクティスエリアのニュース、タグ、タグと紐付いた文献（コメント付き書籍、パブコメ、ガイドライン、法令）を取得する。
     * @summary プラクティスエリアの各種リソースのスニペットを取得する。
     * @param {string} id プラクティスエリア ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getPracticeAreaSnippets(id: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getPracticeAreaSnippets(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary ID で指定された文献に設定されているクイックアクセスを取得する
     * @param {string} naturalId 文献の ID (書籍の ISBN など)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getQuickAccessItemsOfDocument(naturalId: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getQuickAccessItemsOfDocument(naturalId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 最近追加された書籍の配列を返す。
     * @summary 最近追加された書籍一覧を取得する
     * @param {number} [from] 検索結果の何件目から表示するのか
     * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getRecentlyAddedDocuments(from?: number, size?: number, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getRecentlyAddedDocuments(from, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 最近追加された書籍の配列を返す。
     * @summary 最近追加された Store で購入可能なコンテンツの一覧を取得する
     * @param {number} [from] 検索結果の何件目から表示するのか
     * @param {number} [size] 開始位置から検索結果を何件目までを表示させるのか
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getRecentlyAddedPurchasableDocuments(from?: number, size?: number, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getRecentlyAddedPurchasableDocuments(from, size, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 資料の1ページを抽出し、所有者情報や発効日、紙面に透かしのロゴなどを追加してPDFデータとして返却する。
     * @summary 資料から指定されたページをPDFデータとして抽出する
     * @param {string} id 
     * @param {number} pageSeq 抽出するページ番号
     * @param {string} [host] リクエスト元のホスト名
     * @param {string} [download] レスポンスが添付ファイルとして保存されるべきものかどうかを指定するためのフラグ
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getSinglePagePDF(id: string, pageSeq: number, host?: string, download?: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getSinglePagePDF(id, pageSeq, host, download, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 一部の資料にはタグが付与されており、この API ではそのタグを ID 指定することで該当する資料を返却する。 この API のレスポンスには、書誌情報しか存在していない (すなわち Legalscape には未掲載の) 書籍も含まれる。
     * @summary ID で指定されたタグが付与されている資料を取得する。
     * @param {string} id タグの ID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public getTaggedDocuments(id: string, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).getTaggedDocuments(id, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * IDで指定したドキュメント内に、検索キーワードにヒットする箇所があるかどうかを検索して結果を返す
     * @summary 指定したドキュメント内にあるキーワードがあるかどうかを検索して返す
     * @param {string} id 
     * @param {string} [keyword] 対象のドキュメント内を検索するキーワード
     * @param {boolean} [accumulateSubsections] サブセクション以下のヒット件数の累計値を計算して返却するか否か
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public lookupInDocByKeyword(id: string, keyword?: string, accumulateSubsections?: boolean, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).lookupInDocByKeyword(id, keyword, accumulateSubsections, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * IDで指定したドキュメント内に、検索キーワードにヒットする箇所があるかどうかを検索して結果を返す
     * @summary 指定したドキュメント内にあるキーワードがあるかどうかを検索して返す
     * @param {Array<string>} naturalId 
     * @param {Array<string>} [keywords] 対象のドキュメント内を検索するキーワード
     * @param {boolean} [accumulateSubsections] サブセクション以下のヒット件数の累計値を計算して返却するか否か
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DocumentsApi
     */
    public lookupInDocsByKeyword(naturalId: Array<string>, keywords?: Array<string>, accumulateSubsections?: boolean, options?: AxiosRequestConfig) {
        return DocumentsApiFp(this.configuration).lookupInDocsByKeyword(naturalId, keywords, accumulateSubsections, options).then((request) => request(this.axios, this.basePath));
    }
}
