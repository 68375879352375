import { PropType, defineComponent } from 'vue';

import * as Constants from '@/constants';
import NO_IMAGE from '@/assets/noimage.png';
import { DocBrowseEventExcerptWithRange, ParsedSearchEvent } from 'wklr-backend-sdk/repos/histories';
import { BookSnippet, DocRecord, DocumentTypeEnum } from 'wklr-backend-sdk/models';
import { PartialSearchQuery } from '@/types/SearchQuery';
import { RecursivePartial } from '@/types/RecursivePartial';
import { formatQuery, formatYmdHmsWithSlash, isExternalViewer } from '@/utility';
import { generateSearchRoute, SearchLocation } from '@/utils/routeUtils';
import { getAvailableViewers, isAccessible } from '@/utils/documentUtils';
import { isAccessibleDocRecord, shouldAppendNewIcon } from '@/utils/documentUtils';
import { useStore } from '@/store/useStore';

import DocumentTypeIcon from '@/components/document-type-icon.vue';
import Icon from '@/components/renewal/common/icon.vue';
import SiteMenuHistoryMetaInfo from '@/components/renewal/header/site-menu-history-meta-info.vue';
import ViewerLink from '@/components/viewer-link.vue';

type History = DocBrowseEventExcerptWithRange | ParsedSearchEvent;

export default defineComponent({
  name: 'SiteMenuHistory',
  components: {
    DocumentTypeIcon,
    Icon,
    SiteMenuHistoryMetaInfo,
    ViewerLink,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    histories: {
      type: Array as PropType<History[]>,
      required: true,
    },
    disablePersonalFeature: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(_props, { emit }) {
    const store = useStore();

    // ================
    // 各履歴の表示用の処理
    // ================
    const hiddenLabelPackIds = [1];

    const generateHistoryRoute = (query: PartialSearchQuery): SearchLocation => {
      return generateSearchRoute(query, Constants.Search.option);
    };

    const isTagOnlyQuery = (query: RecursivePartial<PartialSearchQuery>): boolean => {
      return Object.keys(query).length === 1 && query.tagId !== undefined;
    };

    const assertHasDocument = (historyItem: History): historyItem is DocBrowseEventExcerptWithRange => {
      return 'document' in historyItem;
    };

    const shouldShowNewIcon = (document: DocRecord) => {
      if (isAccessibleDocRecord(document)) {
        return shouldAppendNewIcon(document.collectedAt);
      }
      return false;
    };

    const assertIsDocRecord = (document: DocRecord | BookSnippet): document is DocRecord => {
      return 'type' in document;
    };

    const docRecordType = (document: DocRecord | BookSnippet): DocumentTypeEnum | undefined => {
      return 'type' in document ? document.type : undefined;
    };

    const accessible = (document: DocRecord): boolean => {
      return 'docAccessible' in document && document.docAccessible;
    };

    const showShareUrlDialog = (document: DocRecord): void => {
      store.commit('setShareUrlDialogDocument', document);
    };

    const purchased = (document: DocRecord): boolean => {
      if (!('purchased' in document)) {
        return false;
      }
      return document.purchased ?? false;
    };

    const shouldShowThumbnail = (document: DocRecord): boolean => {
      return docRecordType(document) !== DocumentTypeEnum.Law;
    };

    const pathToLatestBook = (document: DocRecord): SearchLocation | undefined => {
      if (!('latestEditionNaturalId' in document)) return;
      if (document.latestEditionNaturalId === document.id) return;
      return generateSearchRoute({ keyword: 'urn:isbn:' + document.latestEditionNaturalId }, Constants.Search.option);
    };

    const isbnLink = (document: DocRecord | BookSnippet): string | null => {
      let isbn = '';
      if ('uri' in document) {
        const parts = document.uri.split(':');
        if (parts.length < 3) return null;
        if (parts[2] === '') return null;
        isbn = parts[2];
      } else if ('isbn' in document) {
        isbn = document.isbn;
      } else {
        return null;
      }
      return `https://ja.wikipedia.org/wiki/%E7%89%B9%E5%88%A5:%E6%96%87%E7%8C%AE%E8%B3%87%E6%96%99?isbn=${isbn}`;
    };

    const documentLabel = (document: DocRecord): string | undefined => {
      // アクセシブルでない場合は確定できないのでラベルを表示しない
      if (!isAccessible(document)) return undefined;

      // メタデータしか持っていない場合はラベルを表示しない
      if (document.isMetadataOnly) return undefined;

      // 法律文は常に Web のみなのでラベルを表示しない
      if (document.type === DocumentTypeEnum.Law) return undefined;

      const viewers = getAvailableViewers(document);
      // 添付のみのなど閲覧できない文献は本文なしとして扱う
      if (viewers.length === 0) return '本文なし';
      if (viewers.length > 1) return undefined;
      switch (viewers[0]) {
        case 'web':
          return 'Webのみ';
        case 'pdf':
          return 'PDFのみ';
      }
    };

    const historyDocumentToIconName = (type: DocumentTypeEnum) => {
      switch (type) {
        case DocumentTypeEnum.Book:
          return 'book';
        case DocumentTypeEnum.Law:
          return 'format-file';
        case DocumentTypeEnum.Pubcom:
          return 'pubcom';
        case DocumentTypeEnum.Guideline:
          return 'guideline';
        case DocumentTypeEnum.Pdf:
          return 'pdf-box';
        case DocumentTypeEnum.Extinv:
          return 'search';
        case DocumentTypeEnum.Case:
          return 'case';
      }
    };

    const historyQueryToIconName = (query: PartialSearchQuery) => {
      // NOTE: 現状はquery.typeが1つのみのようなので、最初の要素を見る
      switch (query.type?.[0]) {
        case DocumentTypeEnum.Book:
          return 'keyword';
        case DocumentTypeEnum.Law:
          return 'format-file';
        case DocumentTypeEnum.Pubcom:
          return 'pubcom';
        case DocumentTypeEnum.Guideline:
          return 'guideline';
      }
    };

    const handleAnchorClick = () => {
      emit('click:anchor');
    };

    return {
      noImage: NO_IMAGE,
      formatQuery,
      formatYmdHmsWithSlash,
      isAccessible,
      hiddenLabelPackIds,
      generateHistoryRoute,
      isTagOnlyQuery,
      assertHasDocument,
      shouldShowNewIcon,
      assertIsDocRecord,
      isExternalViewer,
      docRecordType,
      accessible,
      showShareUrlDialog,
      purchased,
      pathToLatestBook,
      shouldShowThumbnail,
      isbnLink,
      documentLabel,
      historyDocumentToIconName,
      historyQueryToIconName,
      handleAnchorClick,
    };
  },
});
