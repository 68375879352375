<template>
  <span v-if="isMetadataOnly" :class="{ '-toc': isToc, '-book': isBook, '-law': isLaw }">
    <slot />
  </span>

  <a
    v-else-if="typeof hrefDocument !== 'string' && isAccessible(hrefDocument) && isExternalViewer(hrefDocument.type)"
    :href="hrefDocument.uri"
    class="d-inline-block"
    :class="{ '-toc': isToc, '-book': isBook, '-law': isLaw }"
    :target="target ?? '_blank'"
    @mouseover="$emit('mouseover')"
    @mouseout="$emit('mouseout')"
    @click="$emit('click')"
  >
    <slot />
  </a>

  <nuxt-link
    v-else
    :class="{ '-toc': isToc, '-book': isBook, '-law': isLaw }"
    :to="getLink()"
    :target="target"
    @mouseover.native="$emit('mouseover')"
    @mouseout.native="$emit('mouseout')"
    @click.native="clickHandler"
  >
    <slot />
  </nuxt-link>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { isExternalViewer } from '@/utility';
import { encode_object, decode_object } from 'rison';
import { PartialSearchQuery } from '@/types/SearchQuery';
import { DocRecord, DocumentTypeEnum } from 'wklr-backend-sdk/models';
import { isAccessible } from '@/utils/documentUtils';

@Component
export default class LinkViewer extends Vue {
  isExternalViewer = isExternalViewer;
  isAccessible = isAccessible;

  @Prop()
  hrefDocument!: DocRecord | string;

  @Prop()
  isToc?: boolean;

  /** emit が最後まで到達する前に遷移が完了されてしまうことがあるので末端のコンポーネントで直接イベントを発火させるためのコールバック */
  @Prop() viewerClickCallback?: () => void;

  @Prop()
  target?: string;

  @Prop()
  docKey?: number;

  @Prop()
  docSeries?: number;

  get docId(): string {
    return typeof this.hrefDocument === 'string' ? this.hrefDocument : this.hrefDocument.id;
  }

  get isBook(): boolean {
    return typeof this.hrefDocument === 'string' ? false : this.hrefDocument.type === DocumentTypeEnum.Book;
  }

  get isLaw(): boolean {
    return typeof this.hrefDocument === 'string' ? false : this.hrefDocument.type === DocumentTypeEnum.Law;
  }

  get searchQuery(): string {
    return this.$route.query.q
      ? encodeURIComponent(this.castQuery(this.$route.query.q))
      : encodeURIComponent(encode_object({ keyword: this.$route.query.os, type: ['book'] } as PartialSearchQuery));
  }

  get isMetadataOnly(): boolean {
    if (typeof this.hrefDocument === 'string') return false;
    if (!isAccessible(this.hrefDocument)) return false;
    return !!this.hrefDocument.isMetadataOnly;
  }

  private castQuery(query: string | (string | null)[]): string {
    if (Array.isArray(query)) {
      return query.toString();
    }

    return query;
  }

  clickHandler(): void {
    this.$telemetry.sendClickTelemetry(
      {
        button: 'serp-open_document__title',
        params: {
          decodedSearchQuery: this.$route.query.q ? decode_object(<string>this.$route.query.q) : undefined,
          docId: this.docId,
        },
      },
      this.$route,
    );
    if (this.viewerClickCallback) this.viewerClickCallback();
    this.$emit('click');
  }

  getLink(): string {
    if (this.$route.name === 'search') {
      return `/document/${this.docId}?q=${this.searchQuery}`;
    } else {
      if (this.docKey !== undefined && this.docSeries !== undefined) {
        // key と series がある場合は、その情報を付与してリンクを生成する
        return `/document/${this.docId}#key=${this.docKey}&series=${this.docSeries}`;
      } else {
        return `/document/${this.docId}`;
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.-toc {
  font-weight: bold;

  &.-book {
    font-size: 16px;
  }

  &.-law {
    font-size: 22px;
  }
}
</style>
