<template>
  <div class="book-panel">
    <QueryOption label="タイトル" :query="bookQuery.title" @update:query="updateTitle" />
    <QueryOption label="著者・編集者" :query="bookQuery.author" @update:query="updateAuthor" />
    <MaxGapOption :max-gaps="bookQuery.maxGaps" @update:max-gaps="updateMaxGaps" />
    <PublisherOption doctype="book" :publisher="bookQuery.publisher" @update:publisher="updatePublisher" />
    <ExcludeOldEditionOption
      :exclude-old-edition="!bookQuery.includeOlderEditions"
      @update:exclude-old-edition="updateExcludeOldEdition"
    />
    <DateOption :published-on="bookQuery.publishedOn" @update:published-on="updatePublishedOn" />
    <div class="book-panel__options">
      <PerPageOption />
      <SortOption />
    </div>
  </div>
</template>

<script lang="ts" setup>
import QueryOption from './options/query-option.vue';
import MaxGapOption from './options/max-gap-option.vue';
import PublisherOption from './options/publisher-option.vue';
import ExcludeOldEditionOption from './options/exclude-old-edition-option.vue';
import DateOption from './options/date-option.vue';
import PerPageOption from './options/per-page-option.vue';
import SortOption from './options/sort-option.vue';
import { PartialSearchQuery } from '@/types/SearchQuery';
import { PublishedOn } from '@/types/SearchQuery';

export type BookPanelEmits = {
  (e: 'update:bookQuery', value: PartialSearchQuery): void;
};

const emit = defineEmits<BookPanelEmits>();

const props = defineProps<{
  bookQuery: PartialSearchQuery;
}>();

const updateTitle = (value: string) => {
  emit('update:bookQuery', { ...props.bookQuery, title: value });
};

const updateAuthor = (value: string) => {
  emit('update:bookQuery', { ...props.bookQuery, author: value });
};

const updateMaxGaps = (value: number) => {
  emit('update:bookQuery', { ...props.bookQuery, maxGaps: value });
};

const updatePublisher = (value: string[]) => {
  emit('update:bookQuery', { ...props.bookQuery, publisher: value });
};

const updateExcludeOldEdition = (value: boolean) => {
  // 除外する場合は true、つまり含めるかどうかとしては false となるので、真偽値を反転させた上でセットする
  emit('update:bookQuery', { ...props.bookQuery, includeOlderEditions: !value });
};

const updatePublishedOn = (value: PublishedOn) => {
  emit('update:bookQuery', { ...props.bookQuery, publishedOn: value });
};
</script>

<style lang="scss" scoped src="./book-panel.scss" />
