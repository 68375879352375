<template>
  <div class="exclude-old-edition-option">
    <label class="label">オプション</label>

    <v-checkbox
      v-model="value"
      class="checkbox"
      label="同じ書籍の古い版を検索から除外する"
      :ripple="false"
      hide-details
    />
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';

export interface ExcludeOldEditionOptionProps {
  excludeOldEdition?: boolean;
}

const props = defineProps<ExcludeOldEditionOptionProps>();

export type ExcludeOldEditionOptionEmits = {
  (e: 'update:exclude-old-edition', value: boolean): void;
};

const emit = defineEmits<ExcludeOldEditionOptionEmits>();

const value = ref<boolean>(props.excludeOldEdition ?? true);
watch(value, () => {
  emit('update:exclude-old-edition', value.value);
});
</script>

<style lang="scss" src="./exclude-old-edition-option.scss" scoped />
