/** https://gist.github.com/homerjam/933336f19ff1302e7a8dd2c6784dd172 */

import hash from 'object-hash';
import sizeof from 'object-sizeof';
import LRUCache from 'lru-cache';
import type { AxiosInstance, AxiosRequestConfig } from 'axios';

const cacheMaxSize = 128 * 1000 * 1000;

type CacheKey = string;

interface CacheEntry {
  data: unknown;
  headers: Record<string, unknown>;
  status: number;
  statusText: string;
}

function getCacheKey(config: AxiosRequestConfig): CacheKey {
  return hash({
    method: config.method,
    url: config.url,
    params: config.params,
    data: config.data,
  });
}

const cache = new LRUCache<CacheKey, CacheEntry>({
  max: cacheMaxSize,
  length: (item: unknown) => sizeof(item),
});

// 将来的に https://github.com/arthurfiorette/axios-cache-interceptor に移行することを見据え、一部の型定義を揃えている
// https://github.com/arthurfiorette/axios-cache-interceptor/blob/519e2e7a11d0f40829fe5434793041ae009b3fca/src/cache/cache.ts#L23
interface CacheConfig {
  ttl: number;
}

declare module 'axios' {
  interface AxiosRequestConfig {
    cache?: CacheConfig;
  }

  interface AxiosResponseConfig {
    cache?: CacheConfig;
  }
}

export function applyCacheInterceptors(axios: AxiosInstance) {
  // メモリ負荷が高くなる恐れがあるため、サーバーサイドではキャッシュを使用しない
  if (typeof window === 'undefined') {
    return;
  }

  axios.interceptors.request.use(
    (request) => {
      if (request.method === 'get' && request.cache?.ttl) {
        const key = getCacheKey(request);

        if (cache.has(key)) {
          const { data, headers, status, statusText } = cache.get(key)!;

          request.data = data;

          // Set the request adapter to send the cached response
          // and prevent the request from actually running
          request.adapter = () => Promise.resolve({ data, status, statusText, headers, config: request, request });
        }
      }

      return request;
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (response) => {
      if (response.config && response.config.cache?.ttl) {
        const key = getCacheKey(response.config);
        const { data, headers, status, statusText } = response;
        cache.set(key, { data, headers, status, statusText }, response.config.cache.ttl);
      }

      return response;
    },
    (error) => Promise.reject(error),
  );
}
