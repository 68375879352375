import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _12b06400 = () => interopDefault(import('../frontend/pages/collections.vue' /* webpackChunkName: "pages/collections" */))
const _66e78004 = () => interopDefault(import('../frontend/pages/debug.vue' /* webpackChunkName: "pages/debug" */))
const _0eff0139 = () => interopDefault(import('../frontend/pages/directories/index.vue' /* webpackChunkName: "pages/directories/index" */))
const _e9b6a602 = () => interopDefault(import('../frontend/pages/history.vue' /* webpackChunkName: "pages/history" */))
const _5fdc4717 = () => interopDefault(import('../frontend/pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _fa4ea8a4 = () => interopDefault(import('../frontend/pages/purchased.vue' /* webpackChunkName: "pages/purchased" */))
const _21b86a00 = () => interopDefault(import('../frontend/pages/recently-added.vue' /* webpackChunkName: "pages/recently-added" */))
const _ac248b1e = () => interopDefault(import('../frontend/pages/recently-added-purchasable.vue' /* webpackChunkName: "pages/recently-added-purchasable" */))
const _0af2be0d = () => interopDefault(import('../frontend/pages/search.vue' /* webpackChunkName: "pages/search" */))
const _638181bc = () => interopDefault(import('../frontend/pages/format/categories/index.vue' /* webpackChunkName: "pages/format/categories/index" */))
const _3c5bd187 = () => interopDefault(import('../frontend/pages/labs/wandh/index.vue' /* webpackChunkName: "pages/labs/wandh/index" */))
const _a7483914 = () => interopDefault(import('../frontend/pages/profile/practice-selector.vue' /* webpackChunkName: "pages/profile/practice-selector" */))
const _312b5d0e = () => interopDefault(import('../frontend/pages/profile/wizard/index.vue' /* webpackChunkName: "pages/profile/wizard/index" */))
const _0cc2ac65 = () => interopDefault(import('../frontend/pages/profile/wizard/apprentice-period-step.vue' /* webpackChunkName: "pages/profile/wizard/apprentice-period-step" */))
const _9bf9b63a = () => interopDefault(import('../frontend/pages/profile/wizard/practice-area-step.vue' /* webpackChunkName: "pages/profile/wizard/practice-area-step" */))
const _4b4fa2d1 = () => interopDefault(import('../frontend/pages/labs/wandh/q/_id.vue' /* webpackChunkName: "pages/labs/wandh/q/_id" */))
const _80e9d6a6 = () => interopDefault(import('../frontend/pages/share/binder/_id/index.vue' /* webpackChunkName: "pages/share/binder/_id/index" */))
const _272155da = () => interopDefault(import('../frontend/pages/format/categories/_id/subcategories/index.vue' /* webpackChunkName: "pages/format/categories/_id/subcategories/index" */))
const _ebc10c18 = () => interopDefault(import('../frontend/pages/share/binder/_id/bookmark/_bookmark/index.vue' /* webpackChunkName: "pages/share/binder/_id/bookmark/_bookmark/index" */))
const _07bf1428 = () => interopDefault(import('../frontend/pages/share/binder/_id/highlight/_highlight/index.vue' /* webpackChunkName: "pages/share/binder/_id/highlight/_highlight/index" */))
const _1020698c = () => interopDefault(import('../frontend/pages/binder/_id.vue' /* webpackChunkName: "pages/binder/_id" */))
const _3275aa64 = () => interopDefault(import('../frontend/pages/directories/_practiceAreaGroup/index.vue' /* webpackChunkName: "pages/directories/_practiceAreaGroup/index" */))
const _f96a332a = () => interopDefault(import('../frontend/pages/document/_id.vue' /* webpackChunkName: "pages/document/_id" */))
const _3cb9c1c8 = () => interopDefault(import('../frontend/pages/directories/_practiceAreaGroup/_practiceArea/index.vue' /* webpackChunkName: "pages/directories/_practiceAreaGroup/_practiceArea/index" */))
const _af890a06 = () => interopDefault(import('../frontend/pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/collections",
    component: _12b06400,
    name: "collections"
  }, {
    path: "/debug",
    component: _66e78004,
    name: "debug"
  }, {
    path: "/directories",
    component: _0eff0139,
    name: "directories"
  }, {
    path: "/history",
    component: _e9b6a602,
    name: "history"
  }, {
    path: "/profile",
    component: _5fdc4717,
    name: "profile"
  }, {
    path: "/purchased",
    component: _fa4ea8a4,
    name: "purchased"
  }, {
    path: "/recently-added",
    component: _21b86a00,
    name: "recently-added"
  }, {
    path: "/recently-added-purchasable",
    component: _ac248b1e,
    name: "recently-added-purchasable"
  }, {
    path: "/search",
    component: _0af2be0d,
    name: "search"
  }, {
    path: "/format/categories",
    component: _638181bc,
    name: "format-categories"
  }, {
    path: "/labs/wandh",
    component: _3c5bd187,
    name: "labs-wandh"
  }, {
    path: "/profile/practice-selector",
    component: _a7483914,
    name: "profile-practice-selector"
  }, {
    path: "/profile/wizard",
    component: _312b5d0e,
    name: "profile-wizard"
  }, {
    path: "/profile/wizard/apprentice-period-step",
    component: _0cc2ac65,
    name: "profile-wizard-apprentice-period-step"
  }, {
    path: "/profile/wizard/practice-area-step",
    component: _9bf9b63a,
    name: "profile-wizard-practice-area-step"
  }, {
    path: "/labs/wandh/q/:id?",
    component: _4b4fa2d1,
    name: "labs-wandh-q-id"
  }, {
    path: "/share/binder/:id",
    component: _80e9d6a6,
    name: "share-binder-id"
  }, {
    path: "/format/categories/:id/subcategories",
    component: _272155da,
    name: "format-categories-id-subcategories"
  }, {
    path: "/share/binder/:id?/bookmark/:bookmark",
    component: _ebc10c18,
    name: "share-binder-id-bookmark-bookmark"
  }, {
    path: "/share/binder/:id?/highlight/:highlight",
    component: _07bf1428,
    name: "share-binder-id-highlight-highlight"
  }, {
    path: "/binder/:id?",
    component: _1020698c,
    name: "binder-id"
  }, {
    path: "/directories/:practiceAreaGroup",
    component: _3275aa64,
    name: "directories-practiceAreaGroup"
  }, {
    path: "/document/:id?",
    component: _f96a332a,
    name: "document-id"
  }, {
    path: "/directories/:practiceAreaGroup/:practiceArea",
    component: _3cb9c1c8,
    name: "directories-practiceAreaGroup-practiceArea"
  }, {
    path: "/",
    component: _af890a06,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
