import _slicedToArray from "@babel/runtime/helpers/esm/slicedToArray";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.match.js";
import { useStore } from '@/store/useStore';
var backendModels = {
  '35': 'GPT-3.5',
  '4': 'GPT-4',
  standard: '標準モデル',
  advanced: '上位モデル',
  /** バリアントを公開しない */
  auto: null
};

/**
 * モデルID (e.g. `v4.4-alpha.0_35`) からバージョン情報をparseし、表示用の情報を返す
 */
export function parseWandhModelVersion(modelID) {
  var match = modelID.match(/^([^_-]+)(-[^_-]+)?_([^_-]+)$/);
  if (!match) {
    throw new Error('Invalid model ID: ' + modelID);
  }

  // 正式版のprerelease部分に-h0をつけてリリースしていたことがあるのでlegacyPrereleaseはもう純粋なprereleaseではない
  var _match = _slicedToArray(match, 4),
    release = _match[1],
    legacyPrerelease = _match[2],
    backendModelID = _match[3];
  var prerelease = legacyPrerelease && legacyPrerelease != '-h0' ? legacyPrerelease : '';
  var ver = release + prerelease;
  var short = backendModels[backendModelID] ? ver + ' (' + backendModels[backendModelID] + ')' : ver;
  var isPrerelease = Boolean(prerelease);
  var suffix = isPrerelease ? ' (テスト版)' : '';
  return {
    isPrerelease: isPrerelease,
    displayName: {
      short: short,
      holmes: 'Holmes ' + short + suffix,
      // Watsonは生成しないのでバックエンドにLLMいない
      watsonB: 'WatsonB ' + ver + suffix,
      watsonC: 'WatsonC ' + ver + suffix,
      watsonG: 'WatsonG ' + ver + suffix
    }
  };
}

/** ユーザーが現在利用可能なモデルの一覧をAPIのquota情報から返す */
export var useWandhAvailableModels = function useWandhAvailableModels() {
  return computed(function () {
    var store = useStore();
    if (!store.state.persistent.labs.wandh.quota) {
      return {
        standard: null,
        advanced: null
      };
    }
    return {
      standard: store.state.persistent.labs.wandh.quota.remainingQuotaByModel.standard.modelId,
      advanced: store.state.persistent.labs.wandh.quota.remainingQuotaByModel.advanced.modelId
    };
  });
};