import type {
  AccessibleDocRecordTiny,
  DocRecord,
  DocRecordTiny,
  AccessibleDocRecord,
} from '@gen/wklr-backend-api/v1/model';

/**
 * ドキュメントが AccessibleDocRecordTiny か UnaccessibleDocRecordTiny かを判別して型アサーションして返す
 * @param doc DocRecordTiny
 * @returns
 */

export function isAccessible(doc: DocRecordTiny): doc is AccessibleDocRecordTiny;
export function isAccessible(doc: DocRecord): doc is AccessibleDocRecord;

export function isAccessible(doc: DocRecordTiny | DocRecord): doc is AccessibleDocRecordTiny | AccessibleDocRecord {
  return doc.docAccessible === true;
}
