<template>
  <div class="law-panel">
    <QueryOption label="タイトル" :query="lawQuery.title" @update:query="updateTitle" />
    <MaxGapOption :max-gaps="lawQuery.maxGaps" @update:max-gaps="updateMaxGaps" />
    <DateOption :published-on="lawQuery.publishedOn" @update:published-on="updatePublishedOn" />
    <div class="law-panel__options">
      <PerPageOption />
      <SortOption />
    </div>
  </div>
</template>

<script setup lang="ts">
import QueryOption from './options/query-option.vue';
import MaxGapOption from './options/max-gap-option.vue';
import DateOption from './options/date-option.vue';
import PerPageOption from './options/per-page-option.vue';
import SortOption from './options/sort-option.vue';
import { PartialSearchQuery } from '@/types/SearchQuery';

const emit = defineEmits(['update:lawQuery']);

const props = defineProps<{
  lawQuery: PartialSearchQuery;
}>();

const updateTitle = (value: string) => {
  emit('update:lawQuery', { ...props.lawQuery, title: value });
};

const updateMaxGaps = (value: number) => {
  emit('update:lawQuery', { ...props.lawQuery, maxGaps: value });
};

const updatePublishedOn = (value: string) => {
  emit('update:lawQuery', { ...props.lawQuery, publishedOn: value });
};
</script>

<style lang="scss" scoped src="./law-panel.scss"></style>
