import type { FormatsApi } from '@gen/wklr-backend-api/v1/api/formats-api';
import type {
  FormatCategoriesIdGet200Response,
  FormatCategory,
  FormatSubcategoryWithFiles,
  CountsByKey,
} from '@gen/wklr-backend-api/v1/model';
import { CacheTTL } from '../constants';

export class FormatRepository {
  constructor(private api: FormatsApi) {}

  async getCategories(): Promise<FormatCategory[]> {
    const { data } = await this.api.formatCategoriesGet();
    return data;
  }

  async getCategory(categoryId: string): Promise<FormatCategoriesIdGet200Response> {
    const { data } = await this.api.formatCategoriesIdGet(categoryId);
    return data;
  }

  async getSubcategories(categoryId: string): Promise<FormatSubcategoryWithFiles[]> {
    const { data } = await this.api.formatCategoriesIdSubcategoriesGet(categoryId);
    return data;
  }

  async getFormatFileCount(): Promise<number> {
    const { data } = await this.api.getFileCount({
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return data.count;
  }

  async getFormatCategoriesCount(): Promise<CountsByKey> {
    const { data } = await this.api.getFileCountByCategory({
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return data;
  }
}
