import { Context } from '@nuxt/types';
import { useAuth } from '@/plugins/auth';
import { isInPaidPeriod } from '@/utils/organizationUtils';

export default async function ({ app, store, redirect, route }: Context) {
  if (route.path.startsWith('/auth')) return;
  if (route.path.startsWith('/profile')) return;

  const { flags, organization } = useAuth().user;

  if (isInPaidPeriod(organization)) {
    // スキップ不可の場合はどんなルートであっても入力ウィザード画面に遷移させる
    if (flags.shouldShowInputProfile && !flags.canSkipInputProfile) {
      redirect(`/profile/wizard?redirect_url=${encodeURIComponent(route.fullPath)}`);
    }
  }
}
