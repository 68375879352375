import { Context } from '@nuxt/types';

export default async function ({ app }: Context) {
  // デバイス登録状況の確認を行う
  // backendのauth.tsでも同様の確認を行なっているが、レスポンスタイミングが遅いことによる
  // UXの問題を解消するため、フロントエンドでも確認を行う
  // デバイス認証が必要ない組織・パスの場合またはデバイスが登録済みの場合、そのまま遷移する
  await app.$repositories.devices.verifyDevice().catch(async (error: { response: { data: { message: string } } }) => {
    if (error.response.data.message === 'deviceLimitReached') {
      // 端末が登録されておらず、最大端末数を超えており、ログイン後初回アクセスでもない場合
      // ログイン時にauth2側でneedDeviceRegistrationがtrueになる
      const needDeviceRegistration = !!document.cookie
        .split(';')
        .find((item) => item.trim() === 'needDeviceRegistration=true');
      if (!needDeviceRegistration) {
        // 初回アクセスでない場合、ログイン画面にリダイレクトする
        return location.assign('/auth/login?code=deviceNotFound');
      }
      // 初回アクセスの場合、このまま進み端末登録を行う
    }
    // 端末が登録されていない場合またはログイン後初回アクセスの場合、端末登録を行う
    const name = '';
    // TODO: auth-2の/api/session/loginで登録処理を行う。複数組織に所属しているユーザーの端末管理を組織単位からユーザー単位に変更する
    await app.$repositories.devices.updateDevices(name);
  });
  // そのまま遷移する
}
