import { defineComponent, computed, PropType } from 'vue';

import { formatYmd } from '@/utility';
import { DocRecord, DocumentTypeEnum } from 'wklr-backend-sdk/models';

import Icon from '@/components/renewal/common/icon.vue';

export default defineComponent({
  name: 'SiteMenuHistoryMetaInfo',
  components: {
    Icon,
  },
  props: {
    document: {
      type: Object as PropType<DocRecord>,
      required: true,
    },
    docRecordType: {
      type: String as PropType<DocumentTypeEnum>,
      required: true,
    },
    pdfUri: {
      type: String,
      required: false,
      default: '',
    },
    authors: {
      type: Array as PropType<string[]>,
      required: false,
      default: () => [],
    },
    publisher: {
      type: String,
      required: false,
      default: '',
    },
    showPublishedOn: {
      type: Boolean,
      required: true,
    },
    publishedOn: {
      type: Date,
      required: false,
      default: null,
    },
    comittieeReport: {
      type: Object as PropType<{
        stockExchange?: string;
        industry?: string;
        quality?: number;
      }>,
      required: true,
    },
  },
  setup(props) {
    const isLaw = computed(() => props.docRecordType === DocumentTypeEnum.Law);

    const qualityIconName = (quality: number): string | undefined => {
      if (quality < 1 && 3 < quality) return;
      const q = quality === 1 ? 'c' : quality === 2 ? 'b' : 'a';
      return `alpha-${q}-circle`;
    };

    return {
      formatYmd,
      isLaw,
      qualityIconName,
    };
  },
});
