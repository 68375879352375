import type { GuidelinesApi } from '../apis';
import { CacheTTL } from '../constants';
import type { KeyCount } from '../models';

export class GuidelinesRepository {
  constructor(private api: GuidelinesApi) {}
  async getCount(): Promise<number> {
    const { data } = await this.api.getGuidelinesCount({
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return data.count;
  }

  async getCountByPublisher(): Promise<KeyCount[]> {
    const { data } = await this.api.getGuidelineCountByPublisher();
    return data.counts;
  }
}
