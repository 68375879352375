import type { DocRecord, DocumentInfoForToc, TocNode, UnaccessibleDocRecord } from '@gen/wklr-backend-api/v1/model';

/** API から返却された TocNode にフロントエンドで必要な情報を付与したもの */
export interface TocNodeExtended extends TocNode {
  docId: string;
  docTitle: string;
  isLeaf: boolean;
  isRoot: boolean;
  folioLabel: string;
  /**
   * 階層の論理深さ
   *
   * depthは例えばコンメンタールでは条を必ず5にするなど飛び飛びの値をとる
   * これを実際に当該文献に出現する値だけの連番に変換したもの
   * （ただし、以下の例のように必ずしも「論理深さは常に親の論理深さ + 1」ではないことに注意）
   *
   * ```
   * 第1部         (depth = 0, logicalDepth = 0)
   *   ├─ 第1章    (depth = 3, logicalDepth = 1)
   *   │  └─ 第1節 (depth = 5, logicalDepth = 2)
   *   └─ 第2章    (depth = 3, logicalDepth = 1)
   * 第2部         (depth = 0, logicalDepth = 0)
   *   └─ 第1節    (depth = 5, logicalDepth = 2) ← 「節」を一貫して2に割り当てる
   * ```
   */
  logicalDepth: number;
}

/** API から返却された DocRecord にフロントエンドで必要な情報を付与したもの */
export type DocRecordExtended = DocRecord & {
  isSeqUnique?: boolean;
  tocInteractive: Record<string, TocNodeExtended>;
};

/** API から返却された UnaccessibleDocRecord にフロントエンドで必要な情報を付与したもの */
export type UnaccessibleDocRecordExtended = UnaccessibleDocRecord & {
  isSeqUnique?: boolean;
  tocInteractive: Record<string, TocNodeExtended>;
};

export const tocMapper = (
  doc: DocumentInfoForToc,
  tocByKey: TocNode[],
  folioPerSeq: string[] | null,
): [string, TocNodeExtended][] => {
  const { docId, docTitle } = doc;
  const allDepthValues = new Set(tocByKey.map(({ depth }) => depth || 0));
  const logicalDepthMap = new Map(
    Array.from(allDepthValues)
      .sort((a, b) => a - b)
      .map((physical, logical) => [physical, logical]),
  );

  const mapped: [string, TocNodeExtended][] = tocByKey.map((toc, index) => {
    const previousPageSeq = index > 0 ? tocByKey[index - 1].pageSeq : undefined;
    let newPageSeq: string | undefined;
    // folioPerSeq に対応するページがある場合はそれを利用する
    if (folioPerSeq !== null) {
      newPageSeq = folioPerSeq[toc.pageSeq];
    }
    // folioPerSeq に対応するページがない場合と、直前の ToC の pageSeq の値が異なっている場合のみ pageSeq の数値をページ番号とみなす（ 0-indexed なので +1 して利用）
    if (folioPerSeq !== null || toc.pageSeq !== previousPageSeq) {
      newPageSeq ||= String(toc.pageSeq + 1);
    }
    let folioLabel = '';
    if (newPageSeq) {
      const label = newPageSeq && isNaN(parseInt(newPageSeq)) ? newPageSeq : `P${newPageSeq}`;
      folioLabel = ` (${label})`;
    }

    return [
      tocReferenceKey(docId, toc.key),
      {
        ...toc,
        depth: toc.depth || 0,
        // mapの作り方からして安全
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        logicalDepth: logicalDepthMap.get(toc.depth || 0)!,
        // &nbsp;はunicodeで\u00A0に割り当てられている。
        // https://ja.wikipedia.org/wiki/%E3%83%8E%E3%83%BC%E3%83%96%E3%83%AC%E3%83%BC%E3%82%AF%E3%82%B9%E3%83%9A%E3%83%BC%E3%82%B9
        label: toc.label || '\u00A0',
        children: toc.children || [],
        docId,
        docTitle,
        isLeaf: toc.children === undefined || toc.children.length === 0,
        isRoot: toc.parent === -1,
        folioLabel,
      },
    ];
  });
  return mapped;
};

export const tocReferenceKeyPrefix = (docId: string) => `${docId}/`;

export const tocReferenceKey = (docId: string, key: number) => `${tocReferenceKeyPrefix(docId)}${key}`;
