<template>
  <v-menu
    offset-x
    :close-on-content-click="false"
    right
    max-width="540"
    min-width="540"
    content-class="book-filter__menu"
    @input="onOpenClose"
  >
    <!-- 開くためのボタン -->
    <template #activator="{ on, attrs }">
      <v-btn x-small icon v-bind="attrs" class="book-filter__next" v-on="on">
        <Icon name="next" size="md" color="gray-300" />
      </v-btn>
    </template>

    <!-- 開いたら表示されるポップアップ -->
    <v-list class="book-filter">
      <!-- 絞り込みフォーム -->
      <div class="book-filter__search">
        <Icon name="search" size="sm" color="gray-300" />
        <input v-model="filterTextInput" type="text" data-1p-ignore data-lpignore autocomplete="off" />
      </div>

      <div class="book-filter__body">
        <!-- Store -->
        <v-list-item class="document-group-header" :disabled="availableBooks.store.length === 0">
          <v-list-item-action>
            <v-checkbox
              v-model="selection.store.all.value"
              class="book-filter__checkbox"
              :indeterminate="selection.store.all.value === 'indeterminate'"
              title="全選択・解除"
              :disabled="availableBooks.store.length === 0"
            />
          </v-list-item-action>
          <v-list-item-title>
            購入書籍
            <small>（{{ availableBooks.store.length.toLocaleString() }}）</small>
          </v-list-item-title>
          <v-list-item-icon title="Legalscape Store">
            <img src="@/assets/store-icon.svg" alt="Legalscape Store" style="height: 20px; width: 20px" />
          </v-list-item-icon>
        </v-list-item>

        <BookFilterItems group="store" :books="availableBooks.store" :limit="5" />

        <v-menu
          v-if="availableBooks.store.length > 5"
          offset-x
          :close-on-content-click="false"
          right
          max-width="540"
          max-height="600"
          content-class="book-filter__sub-menu"
        >
          <template #activator="{ on, attrs }">
            <v-list-item dense class="document-group-expander" v-bind="attrs" title="続きの候補を見る" v-on="on">
              <v-icon>mdi-chevron-down</v-icon>
            </v-list-item>
          </template>
          <v-list color="#f5f5f5">
            <BookFilterItems group="store" :books="availableBooks.store" />
          </v-list>
        </v-menu>

        <!-- Others -->
        <v-list-item class="document-group-header">
          <v-list-item-action>
            <v-checkbox
              v-model="selection.other.all.value"
              class="book-filter__checkbox"
              :indeterminate="selection.other.all.value === 'indeterminate'"
              title="全選択・解除"
            />
          </v-list-item-action>
          <v-list-item-title>その他の全書籍</v-list-item-title>
        </v-list-item>

        <BookFilterItems group="other" :books="availableBooks.other" :limit="5" />

        <v-menu
          offset-x
          :close-on-content-click="false"
          right
          max-width="540"
          max-height="600"
          content-class="book-filter__sub-menu"
        >
          <template #activator="{ on, attrs }">
            <v-list-item dense class="document-group-expander" v-bind="attrs" title="続きの候補を見る" v-on="on">
              <v-icon>mdi-chevron-down</v-icon>
            </v-list-item>
          </template>
          <v-list color="#f5f5f5">
            <BookFilterItems group="other" :books="availableBooks.other" />
          </v-list>
        </v-menu>
      </div>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { ref, computed, inject, provide, Ref } from 'vue';
import { useStore } from '@/store/useStore';
import type { InputQuery } from '@/store';
import BookFilterItems from './book-filter-items.vue';
import { WandhAvailableBooks } from 'wklr-shared/dist/constants';
import Icon from '@/components/renewal/common/icon.vue';

const store = useStore();

const availableBooks =
  // eslint-disable-next-line @typescript-eslint/no-non-null-assertion -- input-formからしか呼んでいない
  inject<Ref<Record<'store' | 'other', { naturalID: string; title: string }[]>>>('availableBooks')!;

const selection = Object.fromEntries(
  (Object.keys(availableBooks.value) as ['store', 'other']).map((group) => {
    const allIDArray = computed(() => availableBooks.value[group].map(({ naturalID }) => naturalID));
    const allIDSet = computed(() => new Set(allIDArray.value));

    /**
     * 書籍指定検索におけるIDリスト
     * （v-listで簡単に扱うためにここでnullではなくIDを埋めてしまう）
     */
    const ids = ref(store.state.persistent.labs.wandh.inputQuery.filter.id[group] ?? allIDArray.value);

    return [
      group,
      {
        all: computed({
          /** 全選択中か全解除中か中間か */
          get() {
            const selected = ids.value.filter((id) => allIDSet.value.has(id)).length;
            const total = allIDSet.value.size;
            return selected === total ? true : selected > 0 ? 'indeterminate' : false;
          },
          /** 全選択・全解除 */
          set(checked) {
            ids.value = checked ? [...allIDArray.value] : [];
          },
        }),
        reloadFromStore() {
          ids.value = store.state.persistent.labs.wandh.inputQuery.filter.id[group] ?? allIDArray.value;
        },
        ids,
      },
    ];
  }),
);
provide('selection', selection);

const filterTextInput = ref<string>('');
provide('filterTextInput', filterTextInput);

function onOpenClose(open: boolean) {
  if (open) {
    // Storeから読み直す
    selection.store.reloadFromStore();
    selection.other.reloadFromStore();

    // 絞り込みをリセット
    filterTextInput.value = '';

    // 選択済みのものは優先して表示
    const selectedStore = new Set(selection.store.ids.value);
    const selectedOther = new Set(selection.other.ids.value);
    availableBooks.value = {
      store: [...availableBooks.value.store].sort(
        (a, b) => Number(selectedStore.has(b.naturalID)) - Number(selectedStore.has(a.naturalID)),
      ),
      other: [...WandhAvailableBooks].sort(
        (a, b) => Number(selectedOther.has(b.naturalID)) - Number(selectedOther.has(a.naturalID)),
      ),
    };
  } else {
    // Storeに保存
    store.commit('setLabsWandhInputQuery', {
      ...store.state.persistent.labs.wandh.inputQuery,
      filter: {
        ...store.state.persistent.labs.wandh.inputQuery.filter,
        id: {
          // 全選択時にはnullにしなければならない
          store: selection.store.all.value === true ? null : selection.store.ids.value,
          other: selection.other.all.value === true ? null : selection.other.ids.value,
        },
      },
    } satisfies InputQuery);
  }
}
</script>

<style scoped lang="scss" src="./book-filter.scss"></style>
