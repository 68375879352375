import AgreementDialog from '@/components/labs/agreement-dialog.vue';
import LabsNavigation from '@/components/labs/navigation.vue';
import ShareUrlDialog from '@/components/share/share-url-dialog.vue';
import SiteHeader from '@/components/renewal/header/site-header.vue';
import { useTelemetry } from '@/plugins/telemetry';
import Spinner from '@/components/renewal/common/spinner.vue';
import { useStore } from '@/store/useStore';
export default defineComponent({
  name: 'LabsLayout',
  components: {
    AgreementDialog: AgreementDialog,
    LabsNavigation: LabsNavigation,
    ShareUrlDialog: ShareUrlDialog,
    SiteHeader: SiteHeader,
    Spinner: Spinner
  },
  setup: function setup() {
    var showAgreementDialog = ref(true);
    var drawer = ref(null);
    var telemetry = useTelemetry();
    var route = useRoute();
    var agreementDialog = ref(null);
    var handleHomeClick = function handleHomeClick() {
      telemetry.sendClickTelemetry({
        button: 'labs-wandh__back-to-home'
      }, route);
    };
    var handleDisclaimerClick = function handleDisclaimerClick() {
      showAgreementDialog.value = true;
      telemetry.sendClickTelemetry({
        button: 'labs-wandh__disclaimer'
      }, route);
    };
    var handleGuideClick = function handleGuideClick() {
      telemetry.sendClickTelemetry({
        button: 'labs-wandh__guide'
      }, route);
    };
    var handleContactClick = function handleContactClick() {
      telemetry.sendClickTelemetry({
        button: 'labs-wandh__contact'
      }, route);
    };
    var handleDrawerClick = function handleDrawerClick() {
      drawer.value = !drawer.value;
      telemetry.sendClickTelemetry({
        button: 'labs-wandh__drawer'
      }, route);
    };
    var store = useStore();
    var loading = store.state.global.loading;
    watch(agreementDialog, function (dialog) {
      dialog === null || dialog === void 0 ? void 0 : dialog.checkAgreement();
    });
    return {
      agreementDialog: agreementDialog,
      showAgreementDialog: showAgreementDialog,
      drawer: drawer,
      loading: loading,
      handleHomeClick: handleHomeClick,
      handleDisclaimerClick: handleDisclaimerClick,
      handleGuideClick: handleGuideClick,
      handleContactClick: handleContactClick,
      handleDrawerClick: handleDrawerClick
    };
  }
});