<template>
  <div class="per-page-option">
    <label for="per-page-option">表示件数</label>
    <div class="per-page-option__value">
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <button type="button" class="per-page-option__select" v-bind="attrs" v-on="on">
            <span>{{ perPage.name }}</span>
            <Icon name="down" size="sm" color="gray-400" />
          </button>
        </template>
        <div class="per-page-option__menu">
          <div class="per-page-option__menu__items">
            <label
              v-for="(pP, index) in perPages"
              :key="index"
              class="per-page-option__menu__item"
              @click="handleClickPerPage"
            >
              <input type="checkbox" :value="pP.value" :checked="pP.value === perPageCount" />
              <span>{{ pP.name }}</span>
            </label>
          </div>
        </div>
      </v-menu>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import Icon from '@/components/renewal/common/icon.vue';
import { useStore } from '@/store/useStore';

const store = useStore();

const perPages = ref<{ value: number; name: string }[]>([
  {
    value: 20,
    name: '20件表示',
  },
  {
    value: 50,
    name: '50件表示',
  },
  {
    value: 100,
    name: '100件表示',
  },
]);

const perPageCount = computed({
  get: () => store.state.persistent.perPage ?? 0,
  set: (newVal) => {
    store.commit('setPerPage', newVal);
  },
});

const perPage = computed(() => {
  const pP = perPages.value.find((perPage) => perPage.value === perPageCount.value);
  if (!pP) {
    return perPages.value[0];
  }
  return pP;
});

const handleClickPerPage = (e: MouseEvent) => {
  const target = e.target as HTMLInputElement;
  perPageCount.value = Number(target.value);
};
</script>

<style lang="scss" src="./per-page-option.scss" scoped />
