// https://developer.mozilla.org/ja/docs/Web/JavaScript/Reference/Global_Objects/Error

import type { UnaccessibleDocRecordTiny } from '@gen/wklr-backend-api/v1/model';
import type { UnaccessibleDocRecordExtended } from './utils/tocUtils';

// https://future-architect.github.io/typescript-guide/exception.html
class BaseCustomError extends Error {
  constructor(...params: Parameters<ErrorConstructor>) {
    super(...params);
    this.name = new.target.name;
    // for es5
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class NonPermitDocumentError extends BaseCustomError {
  constructor(record: UnaccessibleDocRecordTiny, ...params: Parameters<ErrorConstructor>) {
    super(...params);

    if (!('message' in params)) {
      this.message = 'no permission for document';
    }
    this.record = record;
  }

  record!: UnaccessibleDocRecordTiny | UnaccessibleDocRecordExtended;
}
