import { type PartialSearchQuery } from '../types/SearchQuery';
import { DocumentTypeEnum } from '@gen/wklr-backend-api/v1/model';
import type { SearchQueryType } from '../types/SearchQuery';

const checkIsArrayString = (arr: unknown): arr is string[] =>
  Array.isArray(arr) && arr.every((item) => typeof item === 'string');

const checkIsArrayDocumentType = (arr: unknown): arr is SearchQueryType[] =>
  Array.isArray(arr) && arr.every((t: string) => Object.values(DocumentTypeEnum).some((x) => x === t));

const VALID_KEYS = [
  'id',
  'keyword',
  'title',
  'author',
  'publisher',
  'publishedOn',
  'type',
  'tagId',
  'hideOlderEdition',
  'includeOlderEditions',
  'maxGaps',
] as Readonly<string[]>;

export const parseStringToPartialSearchQuery = (queryString: string): PartialSearchQuery => {
  const query: PartialSearchQuery = JSON.parse(queryString);
  if (typeof query.keyword !== 'string') delete query.keyword;
  if (typeof query.id !== 'string') delete query.id;
  if (typeof query.title !== 'string') delete query.title;
  if (typeof query.author !== 'string') delete query.author;
  if (!checkIsArrayString(query.publisher)) delete query.publisher;
  if (query.publishedOn !== undefined) {
    const gte = query.publishedOn.gte ? query.publishedOn.gte : undefined;
    const lte = query.publishedOn.lte ? query.publishedOn.lte : undefined;
    if (!(gte || lte)) {
      delete query.publishedOn;
    } else {
      if (gte) query.publishedOn.gte = gte;
      if (lte) query.publishedOn.lte = lte;
    }
  }
  if (!checkIsArrayDocumentType(query.type)) delete query.type;
  if (!checkIsArrayString(query.tagId)) delete query.tagId;
  if (typeof query.maxGaps !== 'number') delete query.maxGaps;
  const objKeys = Object.keys(query);
  const validKeys = objKeys.filter((key) => VALID_KEYS.includes(key));

  if (validKeys.length < 1) {
    throw new Error(`クエリ文字列のパースエラー: ${queryString}`);
  }
  if (validKeys.length !== objKeys.length) {
    console.error(`対象外のキーがクエリ文字列に含まれています: ${queryString}`);
  }
  return query;
};
