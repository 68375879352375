import { type IconProps } from '@/components/renewal/common/icon.vue';

export interface MenuItem {
  key: string;
  title: string;
  iconName: IconProps['name'];
}

export interface SubMenuItem {
  title: string;
  value?: string;
  children?: SubMenuItem[];
}

export interface SubMenus {
  [key: string]: SubMenuItem[];
}

export const MENU_ITEMS: MenuItem[] = [
  { key: 'newsCategory', title: 'ニュース', iconName: 'news' },
  { key: 'publisher', title: '書籍・雑誌', iconName: 'book' },
  { key: 'guideline', title: 'ガイドライン等', iconName: 'guideline' },
  { key: 'publicComment', title: 'パブコメ', iconName: 'pubcom' },
  { key: 'format', title: '書式ファイル', iconName: 'format-file' },
  { key: 'collection', title: 'マイ・コレクション', iconName: 'my-collection' },
  { key: 'history', title: '履歴', iconName: 'history' },
  { key: 'account', title: 'アカウント', iconName: 'account-circle' },
  { key: 'announce', title: 'お知らせ', iconName: 'bell-circle' },
  { key: 'help', title: 'サポート', iconName: 'help-circle' },
  { key: 'showNewsPanel', title: 'ニュースの表示切替', iconName: 'setting' },
];

export const NEWS_CATEGORIES_DIRECTORIES: Record<string, string> = {
  全て: 'all',
  'ファイナンス・ディスクロージャー': '1/45',
  'エネルギー・インフラ': '2/10',
  会社法: '3/11',
  危機管理: '4/12',
  'M&A': '5/13',
  倒産: '6/16',
  '一般民事・債権管理': '7/18',
  '国際訴訟・仲裁': '7/19',
  '競争法/独禁法': '8/20',
  消費者法: '8/21',
  労働法: '9/22',
  税務: '10/23',
  知的財産法: '11/24',
  '医事・薬事': '12/28',
  海事: '12/29',
  通商: '13/34',
  IT: '11/46',
  行政法: '14/47',
  刑事: '15/48',
};

export const PUBLISHER_SUB_MENUS: SubMenuItem[] = [
  { title: '購入済み書籍', value: '/purchased' },
  { title: '新着書籍一覧', value: '/recently-added' },
];
