<template>
  <v-app>
    <site-header ref="searchBar" layout="document" />

    <nuxt />

    <share-url-dialog ref="shareUrlDialog" />

    <spinner v-show="loading" />
  </v-app>
</template>

<script setup lang="ts">
import { ref, provide } from 'vue';
import SiteHeader from '@/components/renewal/header/site-header.vue';
import ShareUrlDialog from '@/components/share/share-url-dialog.vue';
import Spinner from '@/components/renewal/common/spinner.vue';
import { useStore } from '@/store/useStore';

const searchBar = ref<InstanceType<typeof SiteHeader>>();
// /document/_id ではドロワーの表示非表示と連動して search-bar も表示非表示を切り替えたいため provide する
provide('$search-bar', searchBar);

const store = useStore();
const loading = store.state.global.loading;
</script>

<style lang="scss" src="./default.scss" />
