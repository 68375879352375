<template>
  <div :class="['site-menu-sub', { loading, 'pt-0': isFormat }]">
    <!-- 検索フォーム -->
    <div v-if="!isFormat" class="site-menu-sub__search-box" @click.stop>
      <v-text-field
        v-model="searchText"
        filled
        hide-details
        dense
        prepend-inner-icon="mdi-magnify"
        class="site-menu-sub__search-box__input"
      />
    </div>

    <!-- サブメニュー -->
    <v-list :class="['site-menu-sub__list', { 'pt-0': isFormat }]">
      <!-- スケルトンローダー -->
      <template v-if="loading">
        <v-list-item v-for="index in 5" :key="index" class="site-menu-sub__item">
          <v-list-item-content class="site-menu-sub__content">
            <v-list-item-title class="site-menu-sub__content__title">
              <v-skeleton-loader type="text" boilerplate width="10em" />
            </v-list-item-title>
            <v-list-item-icon class="site-menu-sub__content__next">
              <icon name="next" size="sm" color="gray-300" />
            </v-list-item-icon>
          </v-list-item-content>
        </v-list-item>
      </template>

      <!-- サブメニュー内容 -->
      <template v-else>
        <v-list-item
          v-for="subItem in filteredSubMenu"
          :key="subItem.title"
          class="site-menu-sub__item"
          @click="$emit('click:subMenuItem', menuKey, subItem, $event)"
        >
          <v-list-item-content class="site-menu-sub__content">
            <v-list-item-title class="site-menu-sub__content__title">
              <span>{{ subItem.title }}</span>
              <span v-if="subItem.title in (subMenuCounts ?? {})" class="site-menu-sub__content__count">
                ({{ subMenuCounts?.[subItem.title].count ?? 0 }})
              </span>
            </v-list-item-title>
            <v-list-item-icon class="site-menu-sub__content__next">
              <icon name="next" size="sm" color="gray-300" />
            </v-list-item-icon>
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { VList, VListItem, VListItemContent, VListItemIcon, VListItemTitle, VTextField } from 'vuetify/lib';

import { SubMenuItem } from './site-menu-constants';

import Icon from '@/components/renewal/common/icon.vue';

type CountsByKey = Record<string, { key: string; count: number }>;

const props = defineProps<{
  menuKey: string;
  loading?: boolean;
  subMenu: SubMenuItem[];
  subMenuCounts?: CountsByKey | null;
}>();

defineEmits<{
  (e: 'click:subMenuItem', menuKey: string, subItem: SubMenuItem, event: MouseEvent): void;
}>();

const searchText = ref('');
// サブメニューが書式ファイルの場合、検索ボックスを表示しない
const isFormat = computed(() => props.menuKey === 'format');

const filteredSubMenu = computed(() => {
  const searchTextLower = searchText.value.toLowerCase();
  const hasChildren = props.subMenu.some((item) => (item.children?.length ?? 0) > 0);

  if (hasChildren) {
    return props.subMenu.map((item) => {
      const children = item.children?.filter((child) => child.title.toLowerCase().includes(searchTextLower));
      return { ...item, children };
    });
  }

  return props.subMenu.filter((item) => item.title.toLowerCase().includes(searchTextLower));
});
</script>

<style lang="scss" scoped src="./site-menu-sub.scss" />
