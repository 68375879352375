import type { CancelToken } from 'axios';
import { CacheTTL } from '../constants';
import { NonPermitDocumentError } from '../errors';
import type { DocumentsApi } from '@gen/wklr-backend-api/v1/api/documents-api';
import type {
  BinderItemOfDocument,
  DocRecord,
  DocRecordTiny,
  LookupResult,
  QuickAccessItemsOfDocument,
  FeaturedDocRecord,
} from '@gen/wklr-backend-api/v1/model';
import { BinderTypeEnum } from '@gen/wklr-backend-api/v1/model';
import { isAccessible } from '../utils/documentUtils';
import type { DocRecordExtended, TocNodeExtended, UnaccessibleDocRecordExtended } from '../utils/tocUtils';
import { tocMapper } from '../utils/tocUtils';
import type { Result } from '../types/Result';
import { Failure, Success } from '../types/Result';

export interface GetMyCollectionItemsOfDocumentResults {
  binderItemsResult: Result<BinderItemOfDocument[], Error>;
  quickAccessItemsResult: Result<QuickAccessItemsOfDocument, Error>;
}

const downloadPDFTimeout = 3000;
const defaultRetryCountToDownloadPdf = 3;
const timeoutErrorRegExp = /^timeout of \S+ exceeded$/;

const generateTocExtended = (
  record: DocRecord,
): { tocExtended: { [key: string]: TocNodeExtended }; isSeqUnique?: boolean } => {
  const folioPerSeq = isAccessible(record) ? record.folioPerSeq : null;
  if (record.allVolumesToc !== undefined) {
    const tocExtended = Object.fromEntries(
      record.allVolumesToc.flatMap((toc) => {
        if (toc.docId === record.id) {
          return tocMapper(toc, toc.byKey, folioPerSeq);
        }
        return tocMapper(toc, toc.byKey, null);
      }),
    );
    return { tocExtended };
  } else {
    const tocByKey = record.toc?.byKey || [];
    const tocExtended = Object.fromEntries(
      tocMapper({ docId: record.id, docTitle: record.title }, tocByKey, folioPerSeq),
    );
    const isSeqUnique = tocByKey.length > 0 && tocByKey.every(({ pageSeq }) => pageSeq === tocByKey[0].pageSeq);
    return { tocExtended, isSeqUnique };
  }
};

export class DocumentsRepository {
  constructor(private api: DocumentsApi) {}

  async get(id: string): Promise<DocRecord> {
    return this._get(id, false);
  }

  /**
   * DocRecord の代わりに DocRecordExtended を返却する
   * FIXME: アクセス不能な場合に `NonPermitDocumentError` を返すが、これの中身が非自明になってしまうので Result | Error 型を返して throw しないようにした方がいいかもしれない
   * FIXME: この方が便利かと思ってレポジトリ層で変換するようにしたが、やっぱり利用側でやった方がいいのかもしれない（エラー時も成功時も同じ処理を適用している）
   * @param id docId
   */
  async getExtended(id: string): Promise<DocRecordExtended> {
    return this._get(id, true);
  }

  private async _get(id: string, extend: true): Promise<DocRecordExtended>;
  private async _get(id: string, extend: false): Promise<DocRecord>;
  private async _get(id: string, extend: boolean): Promise<DocRecord | DocRecordExtended> {
    const { data: record } = await this.api.getDocument(id, {
      cache: { ttl: CacheTTL.DEFAULT },
    });

    if (!isAccessible(record)) {
      const { tocExtended, isSeqUnique } = generateTocExtended(record);
      if (isSeqUnique === undefined) {
        throw new NonPermitDocumentError({ ...record, tocInteractive: tocExtended } as UnaccessibleDocRecordExtended);
      } else {
        throw new NonPermitDocumentError({
          ...record,
          tocInteractive: tocExtended,
          isSeqUnique,
        } as UnaccessibleDocRecordExtended);
      }
    }

    if (extend) {
      const { tocExtended, isSeqUnique } = generateTocExtended(record);
      if (isSeqUnique === undefined) {
        return { ...record, tocInteractive: tocExtended };
      } else {
        return { ...record, tocInteractive: tocExtended, isSeqUnique };
      }
    }
    return record;
  }

  async recentlyAdded(size: number): Promise<DocRecordTiny[]> {
    const { data } = await this.api.getRecentlyAddedDocuments(0, size, {
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return data;
  }

  async recentlyAddedPurchasable(size: number): Promise<DocRecordTiny[]> {
    const { data } = await this.api.getRecentlyAddedPurchasableDocuments(0, size, {
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return data;
  }

  async getPdfUrl(docId: string): Promise<string> {
    const { data } = await this.api.getDocumentsPdfUrl(docId, {
      cache: { ttl: CacheTTL.SHORT },
    });
    return data.url;
  }

  async getFeaturedDocumentsLists(): Promise<FeaturedDocRecord[]> {
    const { data } = await this.api.getFeaturedDocumentsLists({
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return data;
  }

  async getSinglePagePdf(
    docId: string,
    pageSeq: number,
    opts: { retryCount?: number; cancelToken?: CancelToken },
  ): Promise<Uint8Array> {
    const retryCount = opts.retryCount || defaultRetryCountToDownloadPdf;

    for (let i = 0; i < retryCount; i++) {
      try {
        const { data } = await this.api.getSinglePagePDF(docId, pageSeq, undefined, undefined, {
          cancelToken: opts.cancelToken,
          responseType: 'arraybuffer',
          timeout: i + 1 !== retryCount ? downloadPDFTimeout : 0, // 最後の一回はtimeout無し
        });
        return data;
      } catch (e) {
        if (!(e instanceof Error && timeoutErrorRegExp.test(e.message))) {
          throw e;
        } else {
          console.info(e);
        }
      }
    }

    throw new Error('download failed'); // ここには到達しないはず
  }

  async getHitsByDocumentId(
    id: string[],
    keywords: string[],
    accumulateSubsections = true,
  ): Promise<{ [id: string]: { [keyword: string]: LookupResult } }> {
    const response = await this.api.lookupInDocsByKeyword(id, keywords, accumulateSubsections, {
      cache: { ttl: CacheTTL.DEFAULT },
    });
    return response.data;
  }

  async getMyCollectionItemsForDocument(naturalId: string): Promise<GetMyCollectionItemsOfDocumentResults> {
    const [binderItemsResult, quickAccessItemsResult] = await Promise.all([
      this.getBinderItems(naturalId),
      this.getQuickAccessItems(naturalId),
    ]);
    return {
      binderItemsResult,
      quickAccessItemsResult,
    };
  }

  private async getBinderItems(naturalId: string): Promise<Result<BinderItemOfDocument[], Error>> {
    try {
      const { data } = await this.api.getBinderItemsOfDocument(naturalId);
      // FIXME: 本来はAPI側でフィルターアウトすべきではあるが、既存エンドポイントなので挙動変更を先送りにしてここで吸収している
      return new Success(data.filter((item) => item.binder.type !== BinderTypeEnum.QuickAccess));
    } catch (error) {
      console.log(error);
      return new Failure(error as Error);
    }
  }

  async getQuickAccessItems(naturalId: string): Promise<Result<QuickAccessItemsOfDocument, Error>> {
    try {
      const { data } = await this.api.getQuickAccessItemsOfDocument(naturalId);
      return new Success(data);
    } catch (error) {
      console.log(error);
      return new Failure(error as Error);
    }
  }
}
