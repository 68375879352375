import type { AxiosInstance, AxiosRequestConfig } from 'axios';
import type { Configuration } from '@gen/wklr-backend-api/v1/configuration';
import { BinderTypeEnum } from '@gen/wklr-backend-api/v1/model';
import type { BindersApiInterface } from '@gen/wklr-backend-api/v1/api/binders-api';
import { BindersApi } from '@gen/wklr-backend-api/v1/api/binders-api';

const exceptionFunc = (): never => {
  throw new Error('現在のアカウントではバインダー・共有機能はご利用できません');
};

export const binderFoldersApiGenerator = (
  isBinderFeatureEnabled: boolean,
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
): BindersApiInterface => {
  const api = new BindersApi(configuration, basePath, axios);
  if (isBinderFeatureEnabled) {
    // バインダー機能が有効な場合は API をそのまま返す
    return api;
  }

  return {
    // バインダー機能が無効な場合はこれらの API は利用できない
    deleteBinder: exceptionFunc,
    deleteBinderFolder: exceptionFunc,
    deleteHighlight: exceptionFunc,
    getBinderWithItems: exceptionFunc,
    getBinderItemsOfDocument: exceptionFunc,
    getSubFoldersAndBinders: exceptionFunc,
    modifyBinder: exceptionFunc,
    modifyBinderFolder: exceptionFunc,
    modifyHighlight: exceptionFunc,
    newBinder: exceptionFunc,
    newBinderFolder: exceptionFunc,
    newHighlight: exceptionFunc,
    getBindersCount: exceptionFunc,
    getItemsCountPerBinder: exceptionFunc,

    // クイックアクセスの操作が発生するため、バインダー機能が無効でもブックマークは利用できる
    deleteBookmark: api.deleteBookmark.bind(api),
    modifyBookmark: api.modifyBookmark.bind(api),
    newPdfBookmark: api.newPdfBookmark.bind(api),
    newWebBookmark: api.newWebBookmark.bind(api),

    // クイックアクセスのみを返す
    async listAllBinders(options?: AxiosRequestConfig) {
      const result = await api.listAllBinders(options);
      return { ...result, data: result.data.filter((b) => b.type === BinderTypeEnum.QuickAccess) };
    },
  };
};
