<template>
  <div class="sort-option">
    <label for="sort-option">並べ替え</label>
    <div class="sort-option__value">
      <v-menu offset-y>
        <template #activator="{ on, attrs }">
          <button type="button" class="sort-option__select" v-bind="attrs" v-on="on">
            <span>{{ order.name }}</span>
            <Icon name="down" size="sm" color="gray-400" />
          </button>
        </template>
        <div class="sort-option__menu">
          <div class="sort-option__menu__items">
            <label v-for="(o, index) in orders" :key="index" class="sort-option__menu__item" @click="handleClickOrder">
              <input type="checkbox" :value="index" :checked="index === orderId" />
              <span>{{ o.name }}</span>
              <Help v-if="o.help" right :description="o.help" />
            </label>
          </div>
        </div>
      </v-menu>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed } from 'vue';
import Icon from '@/components/renewal/common/icon.vue';
import Help from '@/components/renewal/common/help.vue';
import { useStore } from '@/store/useStore';

const store = useStore();

const orders = ref<{ name: string; help?: string }[]>([
  { name: '関連度・重要度順' },
  { name: '発行年月日が新しい順' },
  { name: '発行年月日が古い順' },
  { name: 'タイトル順', help: '文字コード順での並び替え' },
  { name: '著者名順', help: '筆頭著者名の文字コード順での並び替え' },
  { name: '発行元名順', help: '文字コード順での並び替え' },
]);

const order = computed(() => {
  const o = orders.value.find((order, index) => index === orderId.value);
  if (!o) {
    return orders.value[0];
  }
  return o;
});

const orderId = computed({
  get: () => store.state.search.orderId ?? 0,
  set: (newVal) => {
    store.commit('setSearchOrderId', newVal);
  },
});

const handleClickOrder = (e: MouseEvent) => {
  const target = e.target as HTMLInputElement;

  // NOTE: 指定の要素がクリックされたときに、なぜかこの関数が2回呼ばれ、1回目のときはtarget.valueがundefinedになるため、それを回避するための処理
  if (target.value === undefined) {
    return;
  }

  orderId.value = Number(target.value);
};
</script>

<style lang="scss" src="./sort-option.scss" scoped />
