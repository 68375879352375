import { AnnounceKitApi } from '@/api/accounceKitApi';
import { AnnouncementRepository } from '@/api/announcementRepository';
import { AttachmentsRepository, BinderFoldersRepository, SectionsRepository, CitationsRepository, DocumentsRepository, FiguresRepository, HistoriesRepository, SearchesRepository, PracticeAreasRepository, UsersRepository, EGovRepository, KommentarRepository, FormatRepository, PublishersRepository, QuickAccessRepository, DevicesRepository, LabsRepository, AccountsRepository, NewsRepository, binderFoldersApiGenerator, BooksRepository, GuidelinesRepository, PublicCommentsRepository } from 'wklr-backend-sdk/repos';
import { AccountsApi, AttachmentsApi, BooksApi, CitationsApi, DevicesApi, DocumentsApi, EGovApi, FiguresApi, FormatsApi, GuidelinesApi, HistoriesApi, KommentarApi, LabsApi, NewsApi, PracticeAreasApi, PublicCommentsApi, PublishersApi, QuickAccessApi, SearchesApi, SectionsApi, UsersApi } from 'wklr-backend-sdk/apis';
export default (function (_ref, inject) {
  var app = _ref.app;
  var sectionsApiInstance = new SectionsApi(undefined, '', app.$axios);
  var sectionsRepositoryInstance = new SectionsRepository(sectionsApiInstance);
  var documentsApiInstance = new DocumentsApi(undefined, '', app.$axios);
  var documentsRepository = new DocumentsRepository(documentsApiInstance);
  var binderFoldersApiInstance = binderFoldersApiGenerator(app.$auth.permissions.binder, undefined, '', app.$axios);
  var binderFoldersRepositoryInstance = new BinderFoldersRepository(binderFoldersApiInstance, documentsApiInstance, app.$auth.permissions.binder);
  var historyApiInstance = new HistoriesApi(undefined, '', app.$axios);
  var historyRepositoryInstance = new HistoriesRepository(historyApiInstance);
  var announceKitApi = new AnnounceKitApi(app.$axios);
  var announcementRepository = new AnnouncementRepository(announceKitApi);
  var searchesApiInstance = new SearchesApi(undefined, '', app.$axios);
  var searchesRepositoryInstance = new SearchesRepository(searchesApiInstance);
  var attachmentsApi = new AttachmentsApi(undefined, '', app.$axios);
  var attachmentsRepository = new AttachmentsRepository(attachmentsApi);
  var figuresApi = new FiguresApi(undefined, '', app.$axios);
  var figuresRepository = new FiguresRepository(figuresApi);
  var eGovApi = new EGovApi(undefined, '', app.$axios);
  var eGovRepository = new EGovRepository(eGovApi);
  var practiceAreasApi = new PracticeAreasApi(undefined, '', app.$axios);
  var practiceAreasRepository = new PracticeAreasRepository(practiceAreasApi);
  var citationsApi = new CitationsApi(undefined, '', app.$axios);
  var citationsRepository = new CitationsRepository(citationsApi);
  var usersApiInstance = new UsersApi(undefined, '', app.$axios);
  var usersRepository = new UsersRepository(usersApiInstance);
  var kommentarApiInstance = new KommentarApi(undefined, '', app.$axios);
  var kommentarRepository = new KommentarRepository(kommentarApiInstance);
  var publishersApi = new PublishersApi(undefined, '', app.$axios);
  var publishersRepository = new PublishersRepository(publishersApi);
  var quickAccessApi = new QuickAccessApi(undefined, '', app.$axios);
  var quickAccessRepository = new QuickAccessRepository(quickAccessApi);
  var devicesApi = new DevicesApi(undefined, '', app.$axios);
  var devicesRepository = new DevicesRepository(devicesApi);
  var labsApi = new LabsApi(undefined, '', app.$axios);
  var labsRepository = new LabsRepository(labsApi);
  var accountsApi = new AccountsApi(undefined, '', app.$axios);
  var accountsRepository = new AccountsRepository(accountsApi);
  var formatsApi = new FormatsApi(undefined, '', app.$axios);
  var formatRepository = new FormatRepository(formatsApi);
  var newsApi = new NewsApi(undefined, '', app.$axios);
  var newsRepository = new NewsRepository(newsApi);
  var booksApi = new BooksApi(undefined, '', app.$axios);
  var booksRepository = new BooksRepository(booksApi);
  var guidelinesApi = new GuidelinesApi(undefined, '', app.$axios);
  var guidelinesRepository = new GuidelinesRepository(guidelinesApi);
  var publicCommentsApi = new PublicCommentsApi(undefined, '', app.$axios);
  var publicCommentsRepository = new PublicCommentsRepository(publicCommentsApi);

  // TODO: 直接 API を差し込んでいるが厚くなってきたらちゃんと repository を実装する
  var repositories = {
    attachments: attachmentsRepository,
    binderFolders: binderFoldersRepositoryInstance,
    figures: figuresRepository,
    sections: sectionsRepositoryInstance,
    citations: citationsRepository,
    docs: documentsRepository,
    histories: historyRepositoryInstance,
    practiceAreas: practiceAreasRepository,
    users: usersRepository,
    announcement: announcementRepository,
    searches: searchesRepositoryInstance,
    eGov: eGovRepository,
    kommentar: kommentarRepository,
    publishers: publishersRepository,
    quickAccess: quickAccessRepository,
    devices: devicesRepository,
    labs: labsRepository,
    accounts: accountsRepository,
    format: formatRepository,
    news: newsRepository,
    books: booksRepository,
    guidelines: guidelinesRepository,
    publicComments: publicCommentsRepository
  };
  inject('repositories', repositories);
});
export var useRepositories = function useRepositories() {
  var _useNuxtApp = useNuxtApp(),
    $repositories = _useNuxtApp.$repositories;
  return $repositories;
};