<template>
  <!-- NOTE: 
    ヘッダーの検索フォームのz-indexとの関係でここを999に指定しています。 
    ※ 1000以上にすると検索フォームよりこのダイアログが上に来てしまいUI的におかしくなるため
  -->
  <v-dialog v-model="model" width="580" style="z-index: 999" @click:outside="() => cancelClickOutside && cancelHandler">
    <v-card>
      <v-card-title class="headline grey lighten-2"
        ><v-icon v-if="icon" left>{{ icon }}</v-icon> {{ title }}
      </v-card-title>

      <v-card-text v-if="$slots.default" class="pt-2">
        <slot name="default" />
      </v-card-text>

      <v-divider v-if="$slots.default" />

      <v-card-text>
        <p class="mt-2 mb-2">{{ message }}</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="normal" text @click="cancelHandler"> キャンセル </v-btn>
        <v-btn color="primary" :disabled="buttonDisabled" @click="confirmHandler">
          {{ confirmLabel }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { Component, Prop, Vue, Watch } from 'nuxt-property-decorator';
@Component
export default class ConfirmDialog extends Vue {
  @Prop({ required: true }) value!: boolean;
  @Prop({ required: true }) title!: string;
  @Prop({ required: true }) confirmLabel!: string;
  @Prop({ required: true }) message!: string;
  @Prop({ default: false }) disabled!: boolean;
  @Prop({ default: true }) cancelClickOutside!: boolean;
  @Prop() icon!: string;

  /** ダイアログ表示制御用モデル */
  get model(): boolean {
    return this.value;
  }
  set model(value) {
    this.$emit('input', value);
  }

  /** confirm ボタンが押された可動化のフラグ（ダブルクリックのガードのために利用） */
  confirming = false;

  /** 送信ボタンを押した後で、再度ボタンを有効にする。ダイアログの開閉時に実行される他、失敗時に再チャレンジできるようにしたい時は ref 経由で外部から呼ぶ */
  @Watch('value')
  reactivate() {
    this.confirming = false;
  }

  /** ボタンが有効かどうか */
  get buttonDisabled(): boolean {
    return this.disabled || this.confirming;
  }

  cancelHandler() {
    this.$emit('cancel');
    this.model = false;
  }

  confirmHandler() {
    this.$emit('confirm');
    this.confirming = true;
  }
}
</script>
