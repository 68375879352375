<template>
  <div class="public-comment-panel">
    <QueryOption label="案件名" :query="publicCommentQuery.title" @update:query="updateTitle" />
    <MaxGapOption :max-gaps="publicCommentQuery.maxGaps" @update:max-gaps="updateMaxGaps" />
    <PublisherOption doctype="pubcom" :publisher="publicCommentQuery.publisher" @update:publisher="updatePublisher" />
    <DateOption :published-on="publicCommentQuery.publishedOn" @update:published-on="updatePublishedOn" />
    <div class="public-comment-panel__options">
      <PerPageOption />
      <SortOption />
    </div>
  </div>
</template>

<script setup lang="ts">
import QueryOption from './options/query-option.vue';
import MaxGapOption from './options/max-gap-option.vue';
import PublisherOption from './options/publisher-option.vue';
import DateOption from './options/date-option.vue';
import PerPageOption from './options/per-page-option.vue';
import SortOption from './options/sort-option.vue';
import { PartialSearchQuery } from '@/types/SearchQuery';

export type PublicCommentPanelEmits = {
  (e: 'update:publicCommentQuery', value: PartialSearchQuery): void;
};

const emit = defineEmits<PublicCommentPanelEmits>();

const props = defineProps<{
  publicCommentQuery: PartialSearchQuery;
}>();

const updateTitle = (value: string) => {
  emit('update:publicCommentQuery', { ...props.publicCommentQuery, title: value });
};

const updateMaxGaps = (value: number) => {
  emit('update:publicCommentQuery', { ...props.publicCommentQuery, maxGaps: value });
};

const updatePublisher = (value: string[]) => {
  emit('update:publicCommentQuery', { ...props.publicCommentQuery, publisher: value });
};

const updatePublishedOn = (value: PartialSearchQuery['publishedOn']) => {
  emit('update:publicCommentQuery', { ...props.publicCommentQuery, publishedOn: value });
};
</script>

<style lang="scss" scoped src="./public-comment-panel.scss" />
