import { isAxiosError } from '../utils/axiosUtis';
import type { BindersApiInterface } from '@gen/wklr-backend-api/v1/api/binders-api';
import type { DocumentsApi } from '@gen/wklr-backend-api/v1/api/documents-api';
import type {
  Binder,
  BinderWithItems,
  BookmarkWithNaturalId,
  DeleteBinderFolderParamsBody,
  DeleteBinderParamsBody,
  DeleteBookmarkParamsBody,
  Folder,
  GetSubFoldersAndBindersResponse,
  HighlightColorEnum,
  HighlightWithNaturalId,
  ModifyBinderFolderParamsBody,
  ModifyBinderParamsBody,
  NewBinderFolderParamsBody,
  NewBinderParamsBody,
  NewHighlightParamsBody,
  NewPdfBookmarkParamsBody,
  NewWebBookmarkParamsBody,
  ModifyHighlightParamsBody,
  ModifyBookmarkParamsBody,
  BinderItemOfDocument,
  HighlightedContent,
  KeyCount,
} from '@gen/wklr-backend-api/v1/model';
import { BinderTypeEnum } from '@gen/wklr-backend-api/v1/model';
import {
  guardBinderItemRequestData,
  guardBinderRequestData,
  guardFolderRequestData,
} from '../utils/binderFoldersUtils';

export class BinderFoldersRepository {
  constructor(
    private binderApi: BindersApiInterface,
    private documentApi: DocumentsApi,
    private isBinderFeatureEnabled: boolean,
  ) {}

  /**
   * 自分が所有するバインダーをすべて返す。クイックアクセスは常に先頭にセットされる
   * @param includesQuickAccess false の場合、クイックアクセスを含めない
   * @returns
   */
  async getAllBinders(includesQuickAccess = true): Promise<Binder[]> {
    const { data } = await this.binderApi.listAllBinders();

    const quickAccessIndex = data.findIndex((binder) => binder.type === BinderTypeEnum.QuickAccess);
    if (quickAccessIndex === -1) {
      return data;
    }
    const quickAccess = data.splice(quickAccessIndex, 1);
    return includesQuickAccess ? [...quickAccess, ...data] : data;
  }

  /**
   * 文献へのブックマーク、ハイライトの一覧をクイックアクセスを除いて返す
   * @param id 文献の ID
   * @returns
   */
  async getBinderItemsForDocument(id: string): Promise<BinderItemOfDocument[]> {
    try {
      const { data } = await this.documentApi.getBinderItemsOfDocument(id);
      return data;
    } catch (error) {
      if (isAxiosError(error)) {
        if (error.response && error.response.status === 404) {
          // RDB に文献のレコードが存在しない場合に 404 が返却されるが、閲覧に不都合が生じるのでそのようなケースをここで握りつぶす
          // FIXME: LS-1282
          console.warn('Ignore 404: ', error);
          return [];
        }
      }
      throw error;
    }
  }

  async getBinderItems(id: string): Promise<BinderWithItems> {
    const { data } = await this.binderApi.getBinderWithItems(id);
    return data;
  }
  async getSharedBinderItems(id: string): Promise<BinderWithItems> {
    const { data } = await this.binderApi.getBinderWithItems(id, true);
    return data;
  }
  async getFolderContents(id = '0'): Promise<GetSubFoldersAndBindersResponse> {
    const { data } = await this.binderApi.getSubFoldersAndBinders(id);
    return data;
  }
  async createFolder(parentId: string | null, name: string): Promise<Folder> {
    const requestData: NewBinderFolderParamsBody = guardFolderRequestData({ parentId, name });
    const { data } = await this.binderApi.newBinderFolder(requestData);
    return data;
  }
  async editFolder(id: string, parentId: string | null, name: string, updatedAt: number): Promise<Folder> {
    const requestData: ModifyBinderFolderParamsBody = guardFolderRequestData({ parentId, name, updatedAt });
    const { data } = await this.binderApi.modifyBinderFolder(id, requestData);
    return data;
  }
  async removeFolder(id: string, updatedAt: number): Promise<void> {
    const requestData: DeleteBinderFolderParamsBody = { updatedAt };
    const { data } = await this.binderApi.deleteBinderFolder(id, requestData);
    return data;
  }
  async createBinder(id = '0', name: string, description: string, highlightColor: HighlightColorEnum): Promise<Binder> {
    const requestData: NewBinderParamsBody = guardBinderRequestData({
      name,
      description,
      highlightColor,
    });
    const { data } = await this.binderApi.newBinder(id, requestData);
    return data;
  }
  async removeBinder(id: string, updatedAt: number): Promise<void> {
    const requestData: DeleteBinderParamsBody = { updatedAt };
    await this.binderApi.deleteBinder(id, requestData);
  }
  async editBinder(
    id: string,
    folderId: string | null,
    name: string,
    description: string,
    highlightColor: HighlightColorEnum,
    updatedAt: number,
  ): Promise<Binder> {
    const requestData: ModifyBinderParamsBody = guardBinderRequestData({
      name,
      folderId,
      description,
      highlightColor,
      updatedAt,
    });
    const { data } = await this.binderApi.modifyBinder(id, requestData);
    return data;
  }
  async addSectionBookmarkToBinder(
    binderId: string,
    docId: string,
    description: string,
    docDataUpdatedAt: number | null,
    key: number,
    sectionTitle: string,
  ): Promise<BookmarkWithNaturalId> {
    const requestData: NewWebBookmarkParamsBody = guardBinderItemRequestData({
      docId,
      key,
      description,
      sectionTitle,
      docDataUpdatedAt,
    });
    const { data } = await this.binderApi.newWebBookmark(binderId, requestData);
    return data;
  }
  async addPageBookmarkToBinder(
    binderId: string,
    docId: string,
    description: string,
    docDataUpdatedAt: number | null,
    pageSeq: number,
    folio: string,
  ): Promise<BookmarkWithNaturalId> {
    const requestData: NewPdfBookmarkParamsBody = guardBinderItemRequestData({
      docId,
      description,
      pageSeq,
      folio,
      docDataUpdatedAt,
    });
    const { data } = await this.binderApi.newPdfBookmark(binderId, requestData);
    return data;
  }
  async removeBookmark(id: string, updatedAt: number): Promise<void> {
    const requestData: DeleteBookmarkParamsBody = { updatedAt };
    await this.binderApi.deleteBookmark(id, requestData);
  }
  async updateBookmark(id: string, binderId: string, description: string, updatedAt: number): Promise<void> {
    const requestData: ModifyBookmarkParamsBody = guardBinderItemRequestData({
      binderId,
      description,
      updatedAt,
    });
    await this.binderApi.modifyBookmark(id, requestData);
  }
  async addHighlightToBinder(
    binderId: string,
    docId: string,
    description: string,
    docDataUpdatedAt: number | null,
    key: number,
    sectionTitle: string,
    precedingText: string,
    highlightedText: string,
    followingText: string,
    highlightedContents: HighlightedContent[],
  ): Promise<HighlightWithNaturalId> {
    const requestData: NewHighlightParamsBody = guardBinderItemRequestData({
      docId,
      key,
      description,
      sectionTitle,
      precedingText,
      highlightedText,
      followingText,
      highlightedContents,
      docDataUpdatedAt,
    });
    const { data } = await this.binderApi.newHighlight(binderId, requestData);
    return data;
  }
  async removeHighlight(id: string, updatedAt: number): Promise<void> {
    await this.binderApi.deleteHighlight(id, { updatedAt });
  }
  async updateHighlight(id: string, binderId: string, description: string, updatedAt: number): Promise<void> {
    const requestData: ModifyHighlightParamsBody = guardBinderItemRequestData({
      binderId,
      description,
      updatedAt,
    });
    await this.binderApi.modifyHighlight(id, requestData);
  }

  async getBindersCount(): Promise<number> {
    const { data } = await this.binderApi.getBindersCount();
    return data.count;
  }
  async getItemsCountPerBinder(): Promise<KeyCount[]> {
    const { data } = await this.binderApi.getItemsCountPerBinder();
    return data.counts;
  }
}
